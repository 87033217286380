<template>
	<div class="layout-topbar">
		<button class="p-link layout-menu-button" @click="onMenuToggle">
			<span class="pi pi-bars"></span>
		</button>

		<div class="layout-topbar-icons">
			<router-link to="/login">
				<button class="p-link layout-menu-button" @click="logout">
					<span class="layout-topbar-icon pi pi-power-off"> Sign Off</span>
				</button>      
            </router-link>
		</div>
	</div>
</template>

<script>
export default {
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		logout() {
			this.$store.dispatch('logout')
			this.$router.push({ name: 'dashboard' })
		}
    }
}
</script>