<template>
    <div>
        <Toast />
        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>
        <ConfirmDialog></ConfirmDialog>
        <div class="p-grid p-mt-4 " v-if="!loading">

            <div class="p-col-12 p-md-5 p-fluid">
                <div class="card">
                    <h4 class="p-text-bold">Employee Info</h4><hr>   
                    <div class="p-fluid">
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Name</label>
                            <div class="p-ml-auto">
                                {{employee.name}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Business Unit</label>
                            <div class="p-ml-auto">
                                {{employee.businessSector.name}} ({{employee.businessSector.abbreviation}})                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Email</label>
                            <div class="p-ml-auto">
                                {{employee.email}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Login ID</label>
                            <div class="p-ml-auto">
                                {{employee.loginId}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Gender</label>
                            <div class="p-ml-auto">
                                {{employee.gender}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Designation</label>
                            <div class="p-ml-auto">
                                {{employee.designation}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Address</label>
                            <div class="p-ml-auto">
                                {{employee.address}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Status</label>
                            <div class="p-ml-auto">
                                {{employee.status}}                      
                            </div>
                        </div><hr>
                    </div>                    
                </div>
            </div>

            <div class="p-col-12 p-md-7">
                <div class="card">
                    <h4 class="p-text-bold">Role Lists</h4><hr>
                    <DataTable :value="roleLists" responsiveLayout="scroll">
                        <Column field="id" header="ID"></Column>
                        <Column field="name" header="Name"></Column>
                        <Column field="role" header="Role Abbreviation"></Column>
                        <Column header="Action">
                            <template #body="{data}">
                                <Button v-if="isEnabled(data.id)" label="Disable" icon="pi pi-times" class="p-button-rounded p-button-danger mr-2" @click="disablePermission(data.id)" iconPos="right"  :disabled="!hasAuthorities(['AGM1'])" />
                                <Button v-else label="Enable" icon="pi pi-check" class="p-button-rounded p-button-success mr-2" @click="enablePermission(data.id)" iconPos="right"  :disabled="!hasAuthorities(['AGM1'])" />
                            </template>						
                        </Column>
                    </DataTable>
                                                      
                </div>              
            </div>
        </div>
    </div>
</template>

<script>
import { requestMixins } from '../../mixins/requestMixins';
import userHasAuthority from "../../mixins/userHasAuthority";

export default {
    mixins: [requestMixins,userHasAuthority],
    data() {
		return {	
			employee: { },
            roleLists: null,
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Employee', to: '/employees'},
                {label: 'Employee Details'},
            ],
            loading: true,
            updateDialog: false,
           
		}
	},
	created() {
		this.index();
        this.getRoles();
	},
	methods: {
        async index() {
			this.employee = (
				await this.getMethod('employee/' + this.$route.params.id)
			).data
			this.loading = false;
		},
        async getRoles() {
			const roles = (await this.getMethod("role/all")).data;

            this.roleLists = roles.filter((role) =>
                role.status !== "DELETE"
            );
		},
        disablePermission(id){
            this.$confirm.require({
                message: 'Are you sure you want to disable?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.updateMethod('employee/permission/disable/' + this.$route.params.id + '/' + id,"")
					.then((data) => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000});
						this.permission_lists = data
                        this.index();
						})
					.catch((error) => {
							this.loading = false;
							this.$toast.add({severity:'error', summary:'Something Wrong', detail:error.response.data.message, life: 3000})
						})
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Cancel', life: 3000});
                }
            });	
        },
        enablePermission(id) {
            this.$confirm.require({
                message: 'Are you sure you want to enable?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.updateMethod('employee/permission/enable/' + this.$route.params.id + '/' + id,"")
					.then((data) => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000});
						this.permission_lists = data
						this.index(); 			
						})
					.catch((error) => {
							this.loading = false;
							this.$toast.add({severity:'error', summary:'Something Wrong', detail:error.response.data.message, life: 3000})
						})
                },
                reject: () => {
                    this.$toast.add({severity:'info', summary:'Cancel', life: 3000});
                }
            });	
        },
        isEnabled(roleId) {
            if(this.employee.roleList.find( ({ id }) => id === roleId )){
                return true
            }else{
                return false
            }
        }
	},
}
</script>