import axios from 'axios';
import store from '../store/index'

export default function setup() {

    axios.interceptors.request.use(function (config) {

        const token = store.getters.getToken;

		if(token){
			config.headers.common["Authorization"] = token
			config.headers.common["Content-Type"] = "application/json"
		}       
        return config;

      }, function (error) {
        
        console.log("Hello",error)
      });
}