<template>
<h2>Term & Conditions</h2>
    <Toast />
	<router-view />
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>