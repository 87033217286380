<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:right>
                     <Button
                        label="Add New"
                        icon="pi pi-plus"
                        class="p-button-primary p-mr-2"
                        @click="openDialog"
                     />
                  </template>
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
               </Toolbar>

               <DataTable
                  ref="dt"
                  :value="lists"
                  :paginator="true"
                  :rows="25"
                  :loading="loading"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 25, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="['name', 'parentCategoryName']"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column field="id" header="ID"></Column>
                  <Column field="name" header="Name"></Column>
                  <Column
                     field="parentCategoryName"
                     header="Parent Category"
                  ></Column>
                  <Column header="Actions">
                     <template #body="{ data }">
                        <Button
                           id="button_1"
                           label="Edit"
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-warning mr-2"
                           @click="edit(data)"
                           iconPos="right"
                        />
                        <Button
                           id="button_2"
                           label="Delete"
                           icon="pi pi-trash"
                           class="p-button-rounded p-button-danger mr-2"
                           @click="
                              deleteMethod('main-category/delete/' + data.id)
                           "
                           iconPos="right"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>

         <Dialog
            header="Product Category"
            v-model:visible="Dialog"
            :style="{ width: '30vw' }"
            :modal="true"
            :closable="false"
         >
            <div class="p-fluid">
               <div class="p-field">
                  <label for="name">Name</label>
                  <Dropdown
                     v-model="input.parentId"
                     :options="lists"
                     optionLabel="name"
                     optionValue="id"
                     placeholder="--- Select One ---"
                  />
               </div>
               <div class="p-field">
                  <label for="type">Type</label>
                  <InputText
                     id="type"
                     type="text"
                     placeholder="Type"
                     v-model="input.name"
                     :class="{ 'p-invalid': v$.input.name.$error }"
                     @keyup.enter="editStatus ? update() : store()"
                  />
                  <small v-if="v$.input.name.$error" class="p-error">
                     <p
                        v-for="error of v$.input.name.$errors"
                        :key="error.$uid"
                     >
                        {{ error.$message.replace("Value", "Type") }}
                     </p>
                  </small>
               </div>
            </div>
            <template #footer>
               <Button
                  label="Cancel"
                  icon="pi pi-times"
                  @click="closeDialog"
                  class="p-button-text"
               />
               <Button
                  :label="buttonLable"
                  icon="pi pi-check"
                  @click="editStatus ? update() : store()"
                  autofocus
               />
            </template>
         </Dialog>
      </div>
   </div>
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";
import { helperMixins } from "../../mixins/helperMixins";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
   mixins: [requestMixins, helperMixins],
   setup: () => ({ v$: useVuelidate() }),
   data() {
      return {
         items: [{ label: "Product Category" }],
      };
   },
   validations() {
      return {
         input: {
            name: {
               required,
            },
         },
      };
   },
   created() {
      this.initFilters1();
      this.index();
   },
   methods: {
      async index() {
         this.lists = (await this.getMethod("main-category/all")).data;
         this.loading = false;
      },
      async store() {
         this.loading = true;
         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.storeMethod("main-category/register", this.input)
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  }),
                     (this.Dialog = false),
                     this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
      async update() {
         this.loading = true;

         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.updateMethod("main-category/update", this.input)
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  }),
                     (this.Dialog = false),
                     this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
   },
};
</script>

<style lang="scss">
#button_1 {
   margin: 2px;
   width: auto;
}
#button_2 {
   margin: 2px;
   width: auto;
}
</style>