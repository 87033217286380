<template>
    <div>
        <Toast />
        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>
        
        <div class="p-d-flex p-jc-center p-mt-4" v-if="!loading">
            <div class="p-col-5 p-fluid card">
                <h4 class="p-text-center p-text-bold"> {{business_sector.name}}</h4>
                <h5 class="p-text-center"> {{business_sector.name}} ({{business_sector.abbreviation}})</h5><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Name</div>
                    <div class="p-col-7 p-text-right">{{business_sector.name}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Abbreviation</div>
                    <div class="p-col-7 p-text-right">{{business_sector.abbreviation}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Email</div>
                    <div class="p-col-7 p-text-right">{{business_sector.email}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">PhoneNo</div>
                    <div class="p-col-7 p-text-right">{{business_sector.phoneNo}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Type</div>
                    <div class="p-col-7 p-text-right">{{business_sector.type}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Address</div>
                    <div class="p-col-7 p-text-right">{{business_sector.address}}</div>
                </div><hr>
                <div class="p-grid">
                    <div class="p-col-5 p-text-bold">Location</div>
                    <div class="p-col-7 p-text-right">{{business_sector.location}}</div>
                </div><hr>

                <div class="p-grid">				
                    <Button label="Update" class="p-button-rounded p-button-info p-mb-2" @click="updateDialog = true" />
                </div>

                <Dialog header="Update Business Sector" v-model:visible="updateDialog" :style="{width: '30vw'}" :modal="true" :closable="false">
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="name">Name</label>
                            <InputText id="name" type="text"  v-model="business_sector.name" />
                        </div>

                        <div class="p-field">
                            <label for="abbreviation">Abbreviation</label>
                            <InputText id="abbreviation" type="text"  v-model="business_sector.abbreviation" />
                        </div>

                        <div class="p-field">
                            <label for="email">Email</label>
                            <InputText id="email" type="text"  v-model="business_sector.email" />
                        </div>

                        <div class="p-field">
                            <label for="phoneNo">Phone No</label>
                            <InputText id="phoneNo" type="text"  v-model="business_sector.phoneNo" />
                        </div>

                        <div class="p-field">
                            <label for="type">Type</label>
                            <InputText id="type" type="text"  v-model="business_sector.type" />
                        </div>

                        <div class="p-field">
                            <label for="address">Address</label>
                            <InputText id="address" type="text"  v-model="business_sector.address" />
                        </div>

                        <div class="p-field">
                            <label for="location">Location</label>
                            <Dropdown v-model="business_sector.locationID" :options="location_lists" optionLabel="city" optionValue="id" placeholder="--- Select One ---" />
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Cancel" icon="pi pi-times" @click="updateDialog = false" class="p-button-text"/>
                        <Button label="Update" icon="pi pi-check" @click="updateBusinessSector" class="p-button-text"/>
                    </template>
                </Dialog>            
            </div>
        </div>
    </div>
</template>

<script>
import { requestMixins } from '../../mixins/requestMixins';
import { helperMixins } from '../../mixins/helperMixins';

export default {
    mixins: [requestMixins,helperMixins],
    data() {
		return {	
			business_sector: { },
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Business Sectors', to: '/business_sectors'},
                {label: 'Business Sectors Details'},
            ],
            loading: true,
            updateDialog: false,
           
		}
	},
	created() {
		this.index();
	},
	methods: {
        async index() {
			this.business_sector = (
				await this.getMethod('business-sector/' + this.$route.params.id)
			).data
			this.loading = false;
		},
        updateBusinessSector() {
            this.loading = true
				this.updateMethod('business-sector/update/' + this.$route.params.id,this.business_sector)
					.then((data) => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000});
						this.updateDialog = false;
						this.index();			
						})
					.catch((error) => {
							this.Dialog = false;
							this.loading = false;
							this.$toast.add({severity:'error', summary:'Something Wrong', detail:error.response.data.message, life: 3000})
						})
        }
	},
}
</script>