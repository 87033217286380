<template>
	<div>
		<Toast />
        <Breadcrumb :home="home" :model="items" />
		
		<ConfirmDialog></ConfirmDialog>

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>

		<div class="p-grid p-mt-4" v-if="!loading">
			<div class="p-col-12">
					<div class="card">
					<Toolbar class="p-mb-4">
						<template v-slot:right>
							<Button label="Add New" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="create()" :disabled="!hasAuthorities(['AGM1','PRO2','FIN3','BSA4','BDA5'])" />
						</template>
					</Toolbar>

					<DataTable :value="lists" :paginator="true" :rows="10"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
						<Column field="id" header="ID"></Column>
						<Column field="name" header="Name"></Column>
						<Column header="Action">
							<template #body="{data}">
								<Button label="Details" icon="pi pi-pencil" class="p-button-rounded p-button-info mr-2"  @click="edit(data.id)" iconPos="right" />
								<Button
									id="button_2"
									label="Delete"
									icon="pi pi-trash"
									class="p-button-rounded p-button-danger mr-2"
									@click="deleteMethod('term-condition/' + data.id)"
									iconPos="right"
									:disabled="!hasAuthorities(['AGM1','PRO2','FIN3','BSA4','BDA5'])"
								/>
							</template>						
						</Column>
					</DataTable>				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import userHasAuthority from "../../mixins/userHasAuthority";
import { requestMixins } from "../../mixins/requestMixins";


export default {
	mixins: [requestMixins,userHasAuthority],
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Terms & Condition'},
            ],
			lists: null,
			loading: true
		}
	},
	created() {
		this.checkQueryMessage();
		this.index();
	},
    methods: {
		async index() {
			this.lists = (await this.getMethod("term-condition")).data;

			this.loading = false;
		},
		create() {
            this.$router.push({ name: 'tandccreate' })
        },
		edit(id) {
			this.$router.push({  name: 'tandcupdate', params: { id: id }})
		},
		checkQueryMessage(){
			if(this.$route.query.msg == "created") {
				this.$toast.add({severity:'success', summary: 'Success', detail:'Successfully Created', life: 3000});
			}else if(this.$route.query.msg == "updated"){
				this.$toast.add({severity:'success', summary: 'Success', detail:'Successfully Updated', life: 3000});
			}else if(this.$route.query.msg == "deleted"){
				this.$toast.add({severity:'success', summary: 'Success', detail:'Successfully Deleted', life: 3000});
			}
		}      
    },
}
</script>