<template>
   <div class="layout-footer">
      <span class="footer-text" style="margin-right: 5px">
         Copyright © 2020 Corporate Information Division (CID). All rights
         reserved.
      </span>
   </div>
</template>

<script>
export default {
   name: "AppFooter",
};
</script>

<style scoped>
</style>