<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <!-- <Toolbar class="p-mb-4">
                  <template v-slot:right>
                     <Button
                        label="Add New"
                        icon="pi pi-plus"
                        class="p-button-primary p-mr-2"
                        @click="openDialog"
                        :disabled="!hasAuthorities(['AGM1'])"
                     /> 
                  </template>
               </Toolbar> -->

               <DataTable
                  :value="lists"
                  :paginator="true"
                  :rows="25"
                  :loading="loading"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 25, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="['name', 'role']"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column field="id" header="ID"></Column>
                  <Column field="name" header="Name"></Column>
                  <Column field="role" header="Role Abbreviation"></Column>
                  <Column header="Actions">
                     <template #body="{ data }">
                        <Button
                           id="button_1"
                           label="Edit"
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-warning mr-2"
                           @click="edit(data)"
                           iconPos="right"
                           :disabled="!hasAuthorities(['AGM1'])"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>

         <Dialog
            header="Role"
            v-model:visible="Dialog"
            :style="{ width: '30vw' }"
            :modal="true"
            :closable="false"
         >
            <div class="p-fluid">
               <div class="p-field">
                  <label for="name">Name</label>
                  <InputText
                     @keyup.enter="editStatus ? update() : store()"
                     id="name"
                     type="text"
                     v-model="input.name"
                     :class="{ 'p-invalid': v$.input.name.$error }"
                  />
               </div>
               <small v-if="v$.input.name.$error" class="p-error">
                  <p v-for="error of v$.input.name.$errors" :key="error.$uid">
                     {{ error.$message.replace("Value", "Name") }}
                  </p>
               </small>
            </div>
            <template #footer>
               <Button
                  label="Cancel"
                  icon="pi pi-times"
                  @click="closeDialog"
                  class="p-button-text"
               />
               <Button
                  :label="buttonLable"
                  icon="pi pi-check"
                  @click="editStatus ? update() : store()"
                  autofocus
               />
            </template>
         </Dialog>
      </div>
   </div>
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";
import { helperMixins } from "../../mixins/helperMixins";
import userHasAuthority from "../../mixins/userHasAuthority";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
   mixins: [requestMixins, helperMixins,userHasAuthority],
   setup: () => ({ v$: useVuelidate() }),
   data() {
      return {
         items: [{ label: "Roles" }],
      };
   },
   validations() {
      return {
         input: {
            name: { required },
         },
      };
   },
   created() {
      this.initFilters1();
      this.index();
   },
   methods: {
      async index() {
         const roleLists = (await this.getMethod("role/all")).data;

         this.lists = roleLists.filter((role) =>
            role.status !== "DELETE"
         );

         this.loading = false;
      },
      // async store() {
      //    const isFormCorrect = await this.v$.$validate();

      //    if (isFormCorrect) {
      //       this.loading = true;
      //       this.storeMethod("role/register", this.input)
      //          .then((data) => {
      //             this.$toast.add({
      //                severity: "success",
      //                summary: "Success",
      //                detail: data.message,
      //                life: 3000,
      //             });
      //             this.Dialog = false;
      //             this.index();
      //          })
      //          .catch((error) => {
      //             this.Dialog = false;
      //             this.loading = false;
      //             this.$toast.add({
      //                severity: "error",
      //                summary: "Something Wrong",
      //                detail: error.response.data.message,
      //                life: 3000,
      //             });
      //          });
      //    } else {
      //       this.loading = false;
      //    }
      // },
      async update() {
         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.loading = true;
            this.updateMethod("role/" + this.input.id, this.input)
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  this.Dialog = false;
                  this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
   },
};
</script>

<style lang="scss">
#button_1 {
   margin: 2px;
   width: auto;
}
#button_2 {
   margin: 2px;
   width: auto;
}
</style>