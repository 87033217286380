<template>

    <h2>Deliveries</h2>
    <router-view />
</template>

<script>
export default {
    
}
</script>