<template>
	<div>
		<Toast />

		<Breadcrumb :home="home" :model="items" />

		<ConfirmDialog></ConfirmDialog>

		<div class="p-grid p-mt-4">
			<div class="p-col-12">
					<div class="card">

					<DataTable :value="lists" :paginator="true" :rows="25" :loading="loading"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,25,50]" responsiveLayout="scroll" sortField="loginId" :sortOrder="1" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						v-model:filters="filters1" filterDisplay="menu" :globalFilterFields="['id','name','abbreviation','type']" >
						<template #header>
							<div class="p-d-flex p-jc-end">
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filter_value" v-on:keyup="SearchEmployee" placeholder="Search" />
								</span>
							</div>
						</template>
						<Column field="loginId" header="ID"></Column>
						<Column field="name" header="Name"></Column>
						<Column field="designation" header="Designation"></Column>
						<Column field="businessSectorName" header="Business Sector"></Column>
                        <Column field="status" header="Status"></Column>
						<Column header="Actions">
							<template #body="{data}">
								<Button id="button_1"  label="View" icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="getDetails(data.id)" iconPos="right" />
							</template>						
						</Column>
					</DataTable>				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { requestMixins } from '../../mixins/requestMixins';
import { helperMixins } from '../../mixins/helperMixins';

export default {
	mixins: [requestMixins,helperMixins],
	data() {
		return {
            items: [
                {label: 'Employee'},
            ],
			filter_value: null
		}
	},
	created() {
        this.initFilters1();
		this.index();
    },
    methods: {
		SearchEmployee() {
			let timeout = null;
			clearTimeout(timeout);
			// Make a new timeout set to go off in 800ms
			timeout = setTimeout(() => {
				this.filters1['global'].value = this.filter_value
			}, 300);
			
		},
		async index() {
			this.lists = (
				await this.getMethod('employee/all')
			).data
			this.loading = false;
		},
        getDetails(id) {
			this.$router.push({  name: 'employees_details', params: { id: id }})
		}
    },
}
</script>

<style lang="scss">
#button_1 {
	margin: 2px;
    width: auto;
}
#button_2 {
	margin: 2px;
    width: auto;
}
</style>