<template>
   <div :class="containerClass" @click="onWrapperClick">
      <AppTopBar @menu-toggle="onMenuToggle" />

      <transition name="layout-sidebar">
         <div
            :class="sidebarClass"
            @click="onSidebarClick"
            v-show="isSidebarVisible()"
         >
            <div class="layout-logo">
               <router-link to="/">
                  <Avatar
                     :image="logo"
                     class="p-mr-2"
                     size="xlarge"
                     shape="circle"
                  />
               </router-link>
            </div>

            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
         </div>
      </transition>

      <div class="layout-main">
         <router-view />
      </div>

      <AppFooter />
   </div>
</template>

<script>
import AppTopBar from "../AppTopbar.vue";
import AppMenu from "../AppMenu.vue";
import AppFooter from "../AppFooter.vue";
import userHasAuthority from "../mixins/userHasAuthority";

export default {
   mixins: [userHasAuthority],
   data() {
      return {
         layoutMode: "static",
         layoutColorMode: "dark",
         staticMenuInactive: false,
         overlayMenuActive: false,
         mobileMenuActive: false,
         permissionLists: "",
         menu: [
            { label: "Dashboard", to: "/" },
            {
               label: "Admin",
               items: [
                  {
                     label: "Role",
                     icon: "pi pi-fw pi-key",
                     to: "/roles",
                     visible: () => (this.hasAuthorities(['AGM1'])),
                  },
                  {
                     label: "Employees",
                     icon: "pi pi-fw pi-users",
                     to: "/employees",
                     //visible: () => this.isUserAdmin("/api/employee"),
                  },
                  {
                     label: "Business Sectors",
                     icon: "pi pi-fw pi-briefcase",
                     to: "/business_sectors",
                     //visible: () => this.isUserAdmin("/api/business-sector"),
                  },
               ],
            },
            {
               label: "Sales",
               items: [
                  {
                     label: "Product Categories",
                     icon: "pi pi-fw pi-bars",
                     to: "/productcategories",
                     //visible: () => this.isUserAdmin("/api/main-category"),
                  },
                  {
                     label: "Product Models",
                     icon: "pi pi-fw pi-bars",
                     to: "/productModals",
                     //visible: () => this.isUserAdmin("/api/product-item"),
                  },
                  {
                     label: "Products",
                     icon: "pi pi-fw pi-bars",
                     to: "/products",
                     //visible: () => this.isUserAdmin("/api/product"),
                  },
                  {
                     label: "Purchase Order",
                     icon: "pi pi-fw pi-envelope",
                     to: "/purchaseOrder",
                     //visible: () => this.isUserAdmin("/api/preOrder"),
                  },
                  {
                     label: "Product Transfer History",
                     icon: "pi pi-fw pi-envelope",
                     to: "/productTransferHistory",
                     //visible: () => this.isUserAdmin("/api/preOrder"),
                  },
                  {
                     label: "CCO Orders",
                     icon: "pi pi-fw pi-envelope",
                     to: "/customerOrders",
                     //visible: () => this.isUserAdmin("/api/customer-orders"),
                  },
                  {
                     label: "Enquiry",
                     icon: "pi pi-fw pi-envelope",
                     to: "/enquiry",
                     visible: () =>(this.hasAuthorities(['AGM1','PRO2','FIN3','BSA4','BDA5'])),
                  },
                  {
                     label: "Delivery",
                     icon: "pi pi-fw pi-envelope",
                     to: "/deliveries",
                     //visible: () => this.isUserAdmin("/api/delivery"),
                  },
                  {
                     label: "Term & Conditions",
                     icon: "pi pi-fw pi-exclamation-circle",
                     to: "/termandconditions",
                     //visible: () => this.isUserAdmin("/api/term-condition"),
                  },
               ],
            },
         ],
      };
   },
   watch: {
      $route() {
         this.menuActive = false;
         this.$toast.removeAllGroups();
      },
   },
   methods: {
      onWrapperClick() {
         if (!this.menuClick) {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
         }

         this.menuClick = false;
      },
      onMenuToggle() {
         this.menuClick = true;

         if (this.isDesktop()) {
            if (this.layoutMode === "overlay") {
               if (this.mobileMenuActive === true) {
                  this.overlayMenuActive = true;
               }

               this.overlayMenuActive = !this.overlayMenuActive;
               this.mobileMenuActive = false;
            } else if (this.layoutMode === "static") {
               this.staticMenuInactive = !this.staticMenuInactive;
            }
         } else {
            this.mobileMenuActive = !this.mobileMenuActive;
         }

         event.preventDefault();
      },
      onSidebarClick() {
         this.menuClick = true;
      },
      onMenuItemClick(event) {
         if (event.item && !event.item.items) {
            this.overlayMenuActive = false;
            this.mobileMenuActive = false;
         }
      },
      onLayoutChange(layoutMode) {
         this.layoutMode = layoutMode;
      },
      onLayoutColorChange(layoutColorMode) {
         this.layoutColorMode = layoutColorMode;
      },
      addClass(element, className) {
         if (element.classList) element.classList.add(className);
         else element.className += " " + className;
      },
      removeClass(element, className) {
         if (element.classList) element.classList.remove(className);
         else
            element.className = element.className.replace(
               new RegExp(
                  "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
                  "gi"
               ),
               " "
            );
      },
      isDesktop() {
         return window.innerWidth > 1024;
      },
      isSidebarVisible() {
         if (this.isDesktop()) {
            if (this.layoutMode === "static") return !this.staticMenuInactive;
            else if (this.layoutMode === "overlay")
               return this.overlayMenuActive;
            else return true;
         } else {
            return true;
         }
      }
   },
   computed: {
      containerClass() {
         return [
            "layout-wrapper",
            {
               "layout-overlay": this.layoutMode === "overlay",
               "layout-static": this.layoutMode === "static",
               "layout-static-sidebar-inactive":
                  this.staticMenuInactive && this.layoutMode === "static",
               "layout-overlay-sidebar-active":
                  this.overlayMenuActive && this.layoutMode === "overlay",
               "layout-mobile-sidebar-active": this.mobileMenuActive,
               "p-input-filled": this.$appState.inputStyle === "filled",
               "p-ripple-disabled": this.$primevue.config.ripple === false,
            },
         ];
      },
      sidebarClass() {
         return [
            "layout-sidebar",
            {
               "layout-sidebar-dark": this.layoutColorMode === "dark",
               "layout-sidebar-light": this.layoutColorMode === "light",
            },
         ];
      },
      logo() {
         return this.layoutColorMode === "dark"
            ? "/assets/layout/images/logo.jpg"
            : "/assets/layout/images/logo.svg";
      },
   },
   beforeUpdate() {
      if (this.mobileMenuActive)
         this.addClass(document.body, "body-overflow-hidden");
      else this.removeClass(document.body, "body-overflow-hidden");
   },
   components: {
      AppTopBar: AppTopBar,
      AppMenu: AppMenu,
      AppFooter: AppFooter,
   },
};
</script>

<style lang="scss">
@import "../App.scss";
</style>
