<template>
	<div>
		<Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>
		<ConfirmDialog></ConfirmDialog>

		<div class="p-d-flex p-jc-center p-mt-4" v-if="!loading">
			<div class="p-col-7 p-fluid card">
				<div class="p-fluid">
					<div class="p-field">
						<label for="name">Name</label>
						<InputText id="name" type="text" :modelValue="data.name" v-model="data.name" />
					</div>
					<div class="p-field">
						<label for="description">Description</label>
						<Editor editorStyle="height: 320px" :modelValue="data.description" v-model="data.description" >
							<template #toolbar>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-list" value="ordered" type="button"></button>
								</span>
							</template>
						</Editor>
					</div>
					<div class="p-grid p-jc-center">
						<div class="p-col-5">
							<Button label="Update" class="p-button-rounded p-mb-2" @click="updateTandC()" :disabled="!hasAuthorities(['AGM1','PRO2','FIN3','BSA4','BDA5'])" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";
import userHasAuthority from "../../mixins/userHasAuthority";


export default {
	mixins: [requestMixins,userHasAuthority],
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
			items: [
				{label: 'Terms & Condition', to: '/termandconditions'},
				{label: 'Update and Delete'},				
			],	
			data: {
				id: "",
				name: "",
				type: "",
				description: ""
            },
			loading: true,
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		async fetchData() {
			const detailsData = (await this.getMethod("term-condition/"+ this.$route.params.id)).data;

			this.data.id = detailsData.id
			this.data.name = detailsData.name
			this.data.description = detailsData.description
			
			this.loading = false
		},
		async updateTandC() {
			await this.updateMethod("term-condition", this.data)
            .then(() => {
                this.$router.push({ name: 'tandclists', query: {msg: 'updated'} })
            })
            .catch((error) => {
				console.log(error)
            });			
		}		
	},
}
</script>