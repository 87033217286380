import store from '../store/index'

export default {
   methods: {
      hasAuthorities(authorities) {
         const authorityArray = authorities.filter((authority) =>
            store.getters.getAuthorities.includes(authority)
         );
         if(authorityArray.length > 0 || this.isAGM){
            return true;
         }else {
            return false;
         }
      },
   },
   computed: {
      isAGM() {
         return store.getters.getAuthorities.includes("AGM1");
      },
   },
};
