import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;
const odooUrl = process.env.VUE_APP_ODOO_URL;

export const requestMixins = {
    methods: {
        getMethod(url) {
            return axios.get(apiUrl + url);
        },
        getMethodWithParams(url,keyword,param) {

            const params = new URLSearchParams([[keyword, param]]);

            return axios.get(apiUrl + url,{params});
        },
        loginMethod(data) {
            //Odoo Login API
            return axios
                .post(odooUrl + "/api/login", data)
                .then((res) => res.data);
        },
        storeMethod(url, data) {
            return axios.post(apiUrl + url, data).then((res) => res.data);
        },
        updateMethod(url, data) {
            return axios.put(apiUrl + url, data).then((res) => res.data);
        },
        deleteMethod(url) {
            this.$confirm.require({
                message: "Are you sure you want to proceed?",
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                accept: () => {
                    (this.loading = true),
                        axios.delete(apiUrl + url).then(
                            (data) => {
                                this.$toast.add({
                                    severity: "success",
                                    summary: "Success",
                                    detail: data.message,
                                    life: 3000,
                                }),
                                    this.index();
                            },
                            (error) => {
                                this.$toast.add({
                                    severity: "error",
                                    summary: "Something Wrong",
                                    detail: error.message,
                                    life: 3000,
                                });
                            }
                        );
                },
                reject: () => {
                    this.$toast.add({
                        severity: "info",
                        summary: "Cancel Delete",
                        life: 3000,
                    });
                },
            });
        },
    },
};
