<template>
    <div>
        <Toast />
        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>
        <ConfirmDialog></ConfirmDialog>
        <div class="p-grid p-mt-4 " v-if="!loading">
            <div class="p-col-12 p-md-5 p-fluid">
                <div class="card">
                    <h4 class="p-text-bold">Product Info</h4><hr>   
                    <div class="p-fluid">
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Category Name</label>
                            <div class="p-ml-auto">
                                {{product_modal.productCategory.name}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Name</label>
                            <div class="p-ml-auto">
                                {{product_modal.name}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Brand Name</label>
                            <div class="p-ml-auto">
                                {{product_modal.brandName}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Stock</label>
                            <div class="p-ml-auto">
                                {{product_modal.stock}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Made-In</label>
                            <div class="p-ml-auto">
                                {{product_modal.country}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Publish Year</label>
                            <div class="p-ml-auto">
                                {{product_modal.publishYear}}                      
                            </div>
                        </div><hr>
                        <div class="p-field p-grid">
                            <label class="p-mr-auto p-text-bold">Description</label>
                            <ScrollPanel style="width: 100%; height: 300px" class="pre-formatted">
                                <p>
                                    {{product_modal.description}}
                                </p>
                            </ScrollPanel>                   
                        </div><hr>
                    </div>                    
                </div>

                <div class="card">
                    <h4 class="p-text-bold">Product Photos</h4><hr>   
                    <Toolbar class="p-mb-4">
                        <template v-slot:right>
                            <FileUpload mode="basic" name="files" :url="'https://hi5api-test.umgclouds.com/api/product/photo/uploadMultipleFiles/' + this.$route.params.id "
                              chooseLabel="Add" @before-send="beforeUpload" @upload="onUpload"/>
                        </template>
                    </Toolbar>

                    <DataTable :value="photos" responsiveLayout="scroll">
                        <Column field="id" header="ID"></Column>
                        <Column header="Image">
                            <template #body="{data}">
                                <img :src="'https://hi5api-test.umgclouds.com/photo/' + data.name" width="200" />
                            </template>
                        </Column>                       
                        <Column header="Delete">
                            <template #body="{data}">
                                <Button  label="Delete" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteMethod('product/photo/delete/' + data.id)" iconPos="right" />
                            </template>						
                        </Column>
                    </DataTable>                
                </div>

                 <div class="card">
                    <h4 class="p-text-bold">Product Documents</h4><hr>   
                    <Toolbar class="p-mb-4">
                        <template v-slot:right>
                            <FileUpload mode="basic" name="files" :url="'https://hi5api-test.umgclouds.com/api/product/document/uploadMultipleFiles/' + this.$route.params.id "
                              chooseLabel="Add" @before-send="beforeUpload" @upload="onUpload"/>
                        </template>
                    </Toolbar>

                    <DataTable :value="documents" responsiveLayout="scroll">
                        <Column field="id" header="ID"></Column>
                        <Column header="Image">
                            <template #body="{data}">
                                <a :href=" 'https://hi5api-test.umgclouds.com/document/' + data.fileName">{{data.label}}</a>
                                
                            </template>
                        </Column>                       
                        <Column header="Delete">
                            <template #body="{data}">
                                <Button  label="Delete" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteMethod('product/document/delete/' + data.id)" iconPos="right" />
                            </template>						
                        </Column>
                    </DataTable>                
                </div>
            </div>

            <div class="p-col-12 p-md-7">
                <div class="card">
                    <h4 class="p-text-bold p-text-center">BU/Branch Stock</h4>
                    <hr />
                    <DataTable
                        :value="branches"
                        showGridlines
                        responsiveLayout="scroll"
                    >
                        <Column field="businessSectorName" header="BU/Branch Name"></Column>
                        <Column field="stock" header="Stock"></Column>
                    </DataTable>
                </div>
                <div class="card">
                    <h4 class="p-text-bold">Specifications</h4><hr>

                    <DataTable :value="specificationLists" showGridlines responsiveLayout="scroll">
                        <Column v-if="edit_spec_status">
                            <template #body="{data}">
                                <b> {{data.title}} </b> <br>
                                {{data.description}}
                            </template>
                        </Column>
                        <Column v-if="!edit_spec_status">
                            <template #body="{data}">
                               <InputText type="text" class="p-inputtext-lg"  v-model="data.title" /><br>
                               <InputText type="text" class="p-inputtext-lg"  v-model="data.description" />
                            </template>
                          
                        </Column>
                        <Column v-if="!edit_spec_status">
                            <template #body="{data}" >
                                <Button  label="Delete" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteSpec(data.id)" iconPos="right" />
                            </template>						
                        </Column>

                        <template #footer>
                            <Button label="Edit" icon="pi pi-pencil" class="p-button-success p-mr-2" v-if="edit_spec_status" @click="editSpec" />
                            <Button label="Add Row" class="p-button-success p-mr-2 " v-if="!edit_spec_status" @click="addSpec(specificationLists)"  />
                            <Button label="Save" class="p-button-warning p-mr-2" v-if="!edit_spec_status" @click="saveSpec" />
                            <Button label="Cancel" class="p-button-danger p-mr-2" v-if="!edit_spec_status" @click="cancelSpec" /> 
                        </template>
                    </DataTable>                                     
                </div>
                
                <div class="card">
                    <h4 class="p-text-bold">Attributes</h4><hr>
                    <Toolbar class="p-mb-4">
                        <template v-slot:right>
                            <Button label="Add New" icon="pi pi-plus" class="p-button-primary p-mr-2" @click="createAttributes" />
                        </template>
                    </Toolbar>

                    <DataTable :value="attributeLists" responsiveLayout="scroll">
                        <Column field="id" header="ID"></Column>
                        <Column field="name" header="Attribute"></Column>
                        <Column field="filter" header="Filter Status"></Column>
                        <Column field="remark" header="Remark"></Column>
                        <Column header="Edit">
                            <template #body="{data}">
                                <Button  label="Edit" icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" @click="editAttribute(data.id)" iconPos="right" />
                            </template>						
                        </Column>
                        <Column header="Delete">
                            <template #body="{data}">
                                <Button  label="Delete" icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteAttribute(data.id)" iconPos="right" />
                            </template>						
                        </Column>
                    </DataTable>

                    <Dialog header="Attribute" v-model:visible="displayAttribute" :style="{width: '30vw'}" :modal="true">
                        <div v-if="!editAttributeStatus">
                            <div class="p-fluid" v-for="(attribute,k) in attributes" :key="k">
                                <div class="p-field">
                                    <label for="name">Attribute Name</label>
                                    <InputText id="name" type="text" placeholder="Name" v-model="attribute.attributeName" />
                                </div>
                                <div class="p-field">
                                    <label for="brandName">Remark</label>
                                    <InputText id="brandName" type="text" placeholder="Remark" v-model="attribute.remark" />
                                </div>
                                <div class="p-field-checkbox">
                                    <Checkbox id="binary" v-model="attribute.filter" :binary="true" />
                                    <label for="binary">Filter</label>
                                </div> 
                                <div class="p-d-flex p-jc-end">
                                    <Button icon="pi pi-plus" class="p-button-rounded p-button-outlined" @click="add(k)" v-show="k == attributes.length-1" />

                                    <Button icon="pi pi-minus" class="p-button-rounded p-button-outlined" @click="remove(k)" v-show="k || ( !k && attributes.length > 1)" />
                                </div>                    
                            </div>
                        </div>
                        <div v-if="editAttributeStatus">
                            <div class="p-fluid">
                                <div class="p-field">
                                    <label for="name">Attribute Name</label>
                                    <InputText id="name" type="text" placeholder="Name" v-model="edit_attribute.attributeName" />
                                </div>
                                <div class="p-field">
                                    <label for="brandName">Remark</label>
                                    <InputText id="brandName" type="text" placeholder="Remark" v-model="edit_attribute.remark" />
                                </div>
                                <div class="p-field-checkbox">
                                    <Checkbox id="binary" v-model="edit_attribute.filter" :binary="true" />
                                    <label for="binary">Filter</label>
                                </div>                    
                            </div>
                        </div>
                        <hr>
                        <template #footer>
                            <Button label="Cancel" icon="pi pi-times" @click="closeAttribute" class="p-button-text"/>
                            <Button v-if="!editAttributeStatus" label="Create" icon="pi pi-check" @click="storeAttribute" autofocus />
                            <Button v-if="editAttributeStatus" label="Update" icon="pi pi-check" @click="updateAttribute" autofocus />
                        </template>
                    </Dialog>
                </div>               
            </div>
        </div>
    </div>
</template>

<script>
import { 
    GetDetailService,
    GetPhotoService,
    GetDocumentService,
    getSpecificationService,
    storeSpecificationService,
    getAttributeService,
    storeAttributeService,
    updateAttributeService,
    deleteAttributeService,
} from '../../service/ProductModalService';
import { requestMixins } from '../../mixins/requestMixins';
import { helperMixins } from '../../mixins/helperMixins';

export default {
	mixins: [requestMixins,helperMixins],
    data() {
		return {
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Product Modal', to: '/productModals'},
                {label: 'Details Product Modal'},
            ],	
			product_modal: "",
            photos: "",
            documents: "",
            loading: true,
            displayAttribute: false,
            displaySpecifications: false,
            editAttributeStatus: false,
            edit_spec_status: true,  
            attributeLists: null,
            branches: null,
            attributes: [
                {attributeName: "", remark: "", filter: ""}
            ],
            edit_attribute: {
                productID: "",
                attributeID: "",
                attributeName: "",
                filter: "",
                remark: "",
            },
            specificationLists: null,
		}
	},
	created() {
        this.index();
	},
	methods: {
        index() {
            this.fetchData();
            this.fetchAttribute();
            this.fetchPhoto();
            this.fetchDocument();
            this.fetchSpecification();
            this.fetchStockQty();
        },
		fetchData() {
			GetDetailService(this.$route.params.id)
			.then(
				data => {
					this.product_modal = data
                    this.loading = false
				},
				error => {
					if(error.response.status == 401){
						console.log(error)
					}
					else{
						console.log(error)
					}
				}
			)	
		},
        fetchAttribute() {
			getAttributeService(this.$route.params.id)
			.then(
				data => {
					this.attributeLists = data
				},
				error => {
					if(error.response.status == 401){
						console.log(error)
					}
					else{
						console.log(error)
					}
				}
			)	
		},
        fetchSpecification() {
			getSpecificationService(this.$route.params.id)
			.then(
				data => {
					this.specificationLists = data
				},
				error => {
					if(error.response.status == 401){
						console.log(error)
					}
					else{
						console.log(error)
					}
				}
			)	
		},
        fetchPhoto() {
            GetPhotoService(this.$route.params.id)
            .then(
				data => {
					this.photos = data
				},
				error => {
					if(error.response.status == 401){
						console.log(error)
					}
					else{
						console.log(error)
					}
				}
			)
        },
        fetchDocument() {
            GetDocumentService(this.$route.params.id,this.$store.getters.getToken)
            .then(
				data => {
					this.documents = data
				},
				error => {
					if(error.response.status == 401){
						console.log(error)
					}
					else{
						console.log(error)
					}
				}
			)
        },
        async fetchStockQty() {
            this.branches = (await this.getMethod("product-modal/productModel/" + this.$route.params.id)).data;
        },
        createAttributes() {
            this.displayAttribute = true;
        },
        closeAttribute() {
            this.displayAttribute = false;
        },
        storeAttribute() {
            this.loading = true,
			storeAttributeService(this.$route.params.id,this.attributes)
				.then( 
					data => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000}),
						this.displayAttribute = false,
                        this.loading = false,
						this.fetchAttribute()
					},
					error => {
						this.$toast.add({severity:'error', summary:'Something Wrong', detail:error.message, life: 3000})
					}
				)           
        },
        editAttribute(AttributeId) {
            const result = this.attributeLists.find( ({ id }) => id === AttributeId );
			this.displayAttribute = true;
			this.editAttributeStatus = true;
			this.edit_attribute.productID = result.productId,
			this.edit_attribute.attributeID = result.id,
			this.edit_attribute.attributeName = result.name,
			this.edit_attribute.filter =  result.filter,
			this.edit_attribute.remark = result.remark
        },
        updateAttribute() {
            this.loading = true,
			updateAttributeService(this.edit_attribute)
				.then( 
					data => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000}),
						this.displayAttribute = false,
						this.editAttributeStatus = false;
                        this.loading = false,
						this.fetchAttribute()
					},
					error => {
						this.$toast.add({severity:'success', summary:'Success', detail:error.message, life: 3000})
					}
				)
        },
        deleteAttribute(id) {
			this.$confirm.require({
                message: 'Are you sure you want to proceed?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
					this.loading = true,
                    deleteAttributeService(id,this.$store.getters.getToken)
					.then(
						data => {
							this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000}),
							this.loading = false,
                            this.fetchAttribute()
						},						
						error => {
							console.log(error)		
						}
					)
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
		},
        editSpec() {
            this.edit_spec_status = false;
        },
        addSpec(data) {

            let specId = data.at(-1).id + 1

            this.specificationLists.push({
                id: specId,
                productId: this.$route.params.id,
                title: "",
                description: ""            
            })
        },
        saveSpec() {
            console.log(this.specificationLists)
            this.loading = true,
			storeSpecificationService(this.$store.getters.getToken,this.$route.params.id,this.specificationLists)
				.then( 
					data => {
						this.$toast.add({severity:'success', summary:'Success', detail:data.message, life: 3000}),
                        this.loading = false,
                        this.edit_spec_status = true
						this.fetchSpecification()
					},
					error => {
						this.$toast.add({severity:'success', summary:'Danger', detail:error.message, life: 3000})
					}
				)
            
        },
        deleteSpec(SpecId) {

            const result = this.specificationLists.filter(item => item.id !== SpecId);

            this.specificationLists = result;
        },
        cancelSpec() {
            this.edit_spec_status = true;

            this.fetchSpecification();
        },
        add () {
            this.attributes.push({
                attributeName: '',
                remark: '',
                filter: ''
            })            
        },
        remove (index) {
            this.attributes.splice(index, 1)
        },
        beforeUpload(request) {
            request.xhr.setRequestHeader('Authorization', this.$store.getters.getToken);
            return request;
        },
        onUpload() {
            this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
            this.index();
        }
	},
}
</script>

<style lang="scss">
.pre-formatted {
  white-space: pre;
}

.pre-formatted .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.pre-formatted .p-scrollpanel-bar {
    background-color: #1976d2;
    opacity: 1;
    transition: background-color .3s;
}

.pre-formatted .p-scrollpanel-bar:hover {
    background-color: #135ba1;
}
</style>