<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:right>
                     <Button
                        label="Add New"
                        icon="pi pi-plus"
                        class="p-button-primary p-mr-2"
                        @click="openDialog"
                        :disabled="!hasAuthorities(['AGM1','PRO2'])"
                     />
                  </template>
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
               </Toolbar>

               <DataTable
                  ref="dt"
                  :value="lists"
                  :paginator="true"
                  :rows="10"
                  :loading="loading"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'id',
                     'productName',
                     'productItemName',
                     'stock',
                     'cco',
                     'standardPrice',
                     'minimumPrice',
                  ]"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 25, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column
                     field="productItemID"
                     header="ID"
                     style="min-width: 5rem"
                  ></Column>
                  <Column
                     field="productName"
                     header="Modal"
                     style="min-width: 12rem"
                  ></Column>
                  <Column
                     field="productItemName"
                     header="Name"
                     style="min-width: 12rem"
                  ></Column>
                  <Column
                     field="stock"
                     header="Stock"
                     style="min-width: 5rem"
                  ></Column>
                  <Column
                     field="cco"
                     header="CCO"
                     style="min-width: 5rem"
                  ></Column>
                  <Column
                     field="standardPrice"
                     header="Standard Price"
                     style="min-width: 10rem"
                  ></Column>
                  <Column
                     field="minimumPrice"
                     header="Minimum Price"
                     style="min-width: 10rem"
                  ></Column>
                  <Column header="Details" style="min-width: 8rem">
                     <template #body="{ data }">
                        <Button
                           label="Details"
                           icon="pi pi-eye"
                           class="p-button-rounded p-button-success mr-2"
                           @click="getDetails(data.productItemID)"
                           iconPos="right"
                        />
                     </template>
                  </Column>
                  <Column header="Clone" style="min-width: 8rem">
                     <template #body="{ data }">
                        <Button
                           label="Clone"
                           icon="pi pi-copy"
                           class="p-button-rounded p-button-info mr-2"
                           @click="clone(data)"
                           iconPos="right"
                           :disabled="!hasAuthorities(['AGM1','PRO2'])"
                        />
                     </template>
                  </Column>
                  <Column header="Purchase Order" style="min-width: 14rem">
                     <template #body="{ data }">
                        <Button
                           label="Add Purchase Order"
                           icon="pi pi-plus"
                           class="p-button-rounded p-button-info"
                           @click="openPreorderDialog(data)"
                           iconPos="left"
                           :disabled="!hasAuthorities(['AGM1','PRO2'])"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>

            <Dialog
               header="Product"
               v-model:visible="Dialog"
               :style="{ width: '30vw' }"
               :modal="true"
               :closable="false"
            >
               <div class="p-fluid">
                  <div class="p-field">
                     <label for="productCategory">Product Modal</label>
                     <Dropdown
                        v-model="input.productModalId"
                        :options="produt_modals"
                        optionLabel="name"
                        optionValue="id"
                        :filter="true"
                        placeholder="--- Select One ---"
                        @change="getAttribute"
                     >
                        <template #option="slotProps">
                           {{ slotProps.option.productCategory.name }} >
                           {{ slotProps.option.name }}
                        </template>
                     </Dropdown>
                  </div>

                  <div class="p-field">
                     <label for="type">Name</label>
                     <InputText
                        type="text"
                        v-model="input.name"
                        :class="{ 'p-invalid': v$.input.name.$error }"
                     />
                  </div>
                  <small v-if="v$.input.name.$error" class="p-error">
                     <p
                        v-for="error of v$.input.name.$errors"
                        :key="error.$uid"
                     >
                        {{ error.$message.replace("Value", "Name") }}
                     </p>
                  </small>

                  <div class="p-field">
                     <label for="type">Standard Price</label>
                     <InputText
                        type="text"
                        v-model="input.standardPrice"
                        :class="{ 'p-invalid': v$.input.standardPrice.$error }"
                     />
                  </div>
                  <small v-if="v$.input.standardPrice.$error" class="p-error">
                     <p
                        v-for="error of v$.input.standardPrice.$errors"
                        :key="error.$uid"
                     >
                        {{ error.$message.replace("Value", "Standard Price") }}
                     </p>
                  </small>

                  <div class="p-field">
                     <label for="type">Minimum Price</label>
                     <InputText
                        type="text"
                        v-model="input.minimumPrice"
                        :class="{ 'p-invalid': v$.input.minimumPrice.$error }"
                     />
                  </div>
                  <small v-if="v$.input.minimumPrice.$error" class="p-error">
                     <p
                        v-for="error of v$.input.minimumPrice.$errors"
                        :key="error.$uid"
                     >
                        {{ error.$message.replace("Value", "Minimum Price") }}
                     </p>
                  </small>

                  <div class="p-field">
                     <label for="description">Currency Unit</label>
                     <Dropdown
                        v-model="input.currency"
                        :options="currencies"
                        optionLabel="name"
                        optionValue="code"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Description</label>
                     <InputText
                        type="text"
                        v-model="input.description"
                        :class="{ 'p-invalid': v$.input.description.$error }"
                     />
                  </div>
                  <small v-if="v$.input.description.$error" class="p-error">
                     <p
                        v-for="error of v$.input.description.$errors"
                        :key="error.$uid"
                     >
                        {{ error.$message.replace("Value", "Description") }}
                     </p>
                  </small>

                  <label>Extra Attributes</label>

                  <div class="p-field">
                     <div
                        class="p-field p-mt-2"
                        v-for="(attribute, k) in attribute_lists"
                        :key="k"
                     >
                        <label for="name">{{ attribute.attributeName }}</label>
                        <label for="name">{{ attribute.name }}</label>
                        <InputText
                           type="text"
                           v-model="input.attributes[k].value"
                        />
                     </div>
                  </div>
               </div>
               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     @click="closeDialog"
                     class="p-button-text"
                  />
                  <Button
                     label="Create"
                     icon="pi pi-check"
                     @click="storeProduct"
                     autofocus
                     class="p-button-text"
                  />
               </template>
            </Dialog>

            <Dialog
               header="Add Pre-Order"
               v-model:visible="preorderDialog"
               :style="{ width: '30vw' }"
               :modal="true"
               :closable="false"
            >
               <div class="p-fluid">
                  <h5>{{ pre_order.productName }}</h5>

                  <div class="p-field">
                     <label for="type">Quantity</label>
                     <InputNumber
                        v-model="pre_order.quantity"
                        :useGrouping="false"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Order Date</label>
                     <Calendar
                        v-model="pre_order.orderDate"
                        :maxDate="maxDate"
                        :manualInput="false"
                        :showIcon="true"
                        dateFormat="yy-mm-dd"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Estimate Arrival Date</label>
                     <Calendar
                        v-model="pre_order.arrivalDate"
                        :minDate="maxDate"
                        :manualInput="false"
                        :showIcon="true"
                        dateFormat="yy-mm-dd"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Remark</label>
                     <InputText type="text" v-model="pre_order.remark" />
                  </div>
               </div>
               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     @click="closePreorderDialog"
                     class="p-button-text"
                  />
                  <Button
                     label="Create"
                     icon="pi pi-check"
                     @click="storePreOrder"
                     autofocus
                     class="p-button-text"
                  />
               </template>
            </Dialog>
         </div>
      </div>
   </div>
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";
import { helperMixins } from "../../mixins/helperMixins";
import userHasAuthority from "../../mixins/userHasAuthority";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
   mixins: [requestMixins, helperMixins,userHasAuthority],
   setup: () => ({ v$: useVuelidate() }),
   data() {
      return {
         produt_modals: "",
         attribute_lists: "",
         pre_order: {},
         preorderDialog: false,
         maxDate: null,
         items: [{ label: "Products" }],
         currencies: [
            { name: "MMK", code: "MMK" },
            { name: "USD", code: "USD" },
         ],
      };
   },
   validations() {
      return {
         input: {
            productModalId: { required },
            name: { required },
            standardPrice: { required },
            minimumPrice: { required },
            currency: { required },
            description: { required },
         },
      };
   },
   created() {
      this.index();
      this.fetchProductModal();
      this.initFilters1();

      this.maxDate = new Date();
   },
   methods: {
      async index() {
         this.lists = (await this.getMethod("product/allWithStock")).data;
         this.loading = false;
      },
      async fetchProductModal() {
         this.produt_modals = (await this.getMethod("product-modal/all")).data;
      },
      getDetails(id) {
         this.$router.push({ name: "product_details", params: { id: id } });
      },
      async getAttribute() {
         this.attribute_lists = (
            await this.getMethod(
               "product/attribute/byProductModal/" + this.input.productModalId
            )
         ).data;

         this.input.attributes = [];

         for (var i = 0; i < this.attribute_lists.length; i++) {
            this.input.attributes.push({
               attributeId: this.attribute_lists[i].id,
               value: "",
            });
         }
      },
      async clone(data) {
         this.input.productModalId = data.productId;
         this.input.name = data.productItemName;
         this.input.standardPrice = data.standardPrice;
         this.input.minimumPrice = data.minimumPrice;
         this.input.currency = data.currency;
         this.input.description = data.description;

         this.attribute_lists = (
            await this.getMethod(
               "product/attribute/byProduct/" + data.productItemID
            )
         ).data;

         this.input.attributes = [];

         for (var i = 0; i < this.attribute_lists.length; i++) {
            this.input.attributes.push({
               attributeId: this.attribute_lists[i].attributeId,
               value: this.attribute_lists[i].value,
            });
         }

         this.Dialog = true;
      },
      async storeProduct() {
         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.loading = true;
            this.storeMethod("product/register", this.input)
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  this.Dialog = false;
                  this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
      openPreorderDialog(data) {
         this.preorderDialog = true;

         this.pre_order.productId = data.productItemID;
         this.pre_order.productName = data.productItemName;
      },
      async storePreOrder() {
         console.log(this.pre_order);

         this.loading = true;
         this.storeMethod("preOrder/register", this.pre_order)
            .then((data) => {
               this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: data.message,
                  life: 3000,
               });
               this.preorderDialog = false;
               this.index();
            })
            .catch((error) => {
               this.preorderDialog = false;
               this.loading = false;
               this.$toast.add({
                  severity: "error",
                  summary: "Something Wrong",
                  detail: error.response.data.message,
                  life: 3000,
               });
            });
      },
      closePreorderDialog() {
         this.preorderDialog = false;

         this.pre_order = {};
      },
   },
};
</script>