<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:right>
                     <Button
                        label="Add New"
                        icon="pi pi-plus"
                        class="p-button-primary p-mr-2"
                        @click="openDialog"
                        :disabled="!hasAuthorities(['AGM1','PRO2'])"
                     />
                  </template>
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
               </Toolbar>

               <DataTable
                  ref="dt"
                  :value="lists"
                  :paginator="true"
                  :rows="25"
                  :loading="loading"
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 25, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'id',
                     'productCategory.name',
                     'name',
                     'brandName',
                     'stock',
                     'country',
                     'publishYear',
                  ]"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column
                     field="id"
                     header="ID"
                     :style="{ width: '20px' }"
                  ></Column>
                  <Column
                     field="productCategory.name"
                     header="Category Name"
                  ></Column>
                  <Column field="name" header="Brand Name"></Column>
                  <Column field="brandName" header="Name"></Column>
                  <Column field="stock" header="Stock"></Column>
                  <Column field="country" header="Country"></Column>
                  <Column field="publishYear" header="Publish Year"></Column>
                  <Column header="Action" :style="{ width: '350px' }">
                     <template #body="{ data }">
                        <Button
                           id="button_1"
                           label="Details"
                           icon="pi pi-eye"
                           class="p-button-rounded p-button-success mr-2"
                           @click="getDetails(data.id)"
                           iconPos="right"
                        />
                        <Button
                           id="button_2"
                           label="Edit"
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-warning mr-2"
                           @click="edit(data)"
                           iconPos="right"
                           :disabled="!hasAuthorities(['AGM1','PRO2'])"
                        />
                        <Button
                           id="button_3"
                           label="Delete"
                           icon="pi pi-trash"
                           class="p-button-rounded p-button-danger mr-2"
                           @click="
                              deleteMethod('product-modal/delete/' + data.id)
                           "
                           iconPos="right"
                           :disabled="!hasAuthorities(['AGM1','PRO2'])"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>
         </div>

         <Dialog
            header="Product Modal"
            v-model:visible="Dialog"
            :style="{ width: '30vw' }"
            :modal="true"
            :closable="false"
         >
            <div class="p-fluid">
               <div class="p-field">
                  <label for="productCategory">Product Category</label>
                  <Dropdown
                     v-model="input.categoryID"
                     :options="selectableCategories"
                     optionLabel="name"
                     optionValue="id"
                     :filter="true"
                     placeholder="--- Select One ---"
                     :class="{ 'p-invalid': v$.input.categoryID.$error }"
                  />
               </div>
               <small v-if="v$.input.categoryID.$error" class="p-error">
                  <p
                     v-for="error of v$.input.categoryID.$errors"
                     :key="error.$uid"
                  >
                     {{ error.$message.replace("Value", "Product Category") }}
                  </p>
               </small>

               <div class="p-field">
                  <label for="name">Name</label>
                  <InputText
                     id="name"
                     type="text"
                     placeholder="Name"
                     v-model="input.name"
                     :class="{ 'p-invalid': v$.input.name.$error }"
                  />
               </div>
               <small v-if="v$.input.name.$error" class="p-error">
                  <p v-for="error of v$.input.name.$errors" :key="error.$uid">
                     {{ error.$message.replace("Value", "Name") }}
                  </p>
               </small>

               <div class="p-field">
                  <label for="brandName">Brand Name</label>
                  <InputText
                     id="brandName"
                     type="text"
                     placeholder="Brand Name"
                     v-model="input.brandName"
                     :class="{ 'p-invalid': v$.input.brandName.$error }"
                  />
               </div>
               <small v-if="v$.input.brandName.$error" class="p-error">
                  <p
                     v-for="error of v$.input.brandName.$errors"
                     :key="error.$uid"
                  >
                     {{ error.$message.replace("Value", "Brand Name") }}
                  </p>
               </small>

               <div class="p-field">
                  <label for="country">Country</label>
                  <InputText
                     id="country"
                     type="text"
                     placeholder="Country"
                     v-model="input.country"
                     :class="{ 'p-invalid': v$.input.country.$error }"
                  />
               </div>
               <small v-if="v$.input.country.$error" class="p-error">
                  <p
                     v-for="error of v$.input.country.$errors"
                     :key="error.$uid"
                  >
                     {{ error.$message.replace("Value", "Country") }}
                  </p>
               </small>

               <div class="p-field">
                  <label for="publishYear">publish Year</label>
                  <InputNumber
                     id="publishYear"
                     v-model="input.publishYear"
                     :useGrouping="false"
                     :class="{ 'p-invalid': v$.input.publishYear.$error }"
                  />
               </div>
               <small v-if="v$.input.publishYear.$error" class="p-error">
                  <p
                     v-for="error of v$.input.publishYear.$errors"
                     :key="error.$uid"
                  >
                     {{ error.$message.replace("Value", "Publish Year") }}
                  </p>
               </small>

               <div class="p-field">
                  <label for="description">Description</label>
                  <Textarea
                     id="description"
                     @keyup.enter="editStatus ? update() : store()"
                     v-model="input.description"
                     :autoResize="true"
                     rows="5"
                     cols="30"
                     :class="{ 'p-invalid': v$.input.description.$error }"
                  />
               </div>
               <small v-if="v$.input.description.$error" class="p-error">
                  <p
                     v-for="error of v$.input.description.$errors"
                     :key="error.$uid"
                  >
                     {{ error.$message.replace("Value", "Description") }}
                  </p>
               </small>
            </div>
            <template #footer>
               <Button
                  label="Cancel"
                  icon="pi pi-times"
                  @click="closeDialog"
                  class="p-button-text"
               />
               <Button
                  :label="buttonLable"
                  icon="pi pi-check"
                  @click="editStatus ? update() : store()"
                  autofocus
               />
            </template>
         </Dialog>
      </div>
   </div>
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";
import { helperMixins } from "../../mixins/helperMixins";
import userHasAuthority from "../../mixins/userHasAuthority";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
   mixins: [requestMixins, helperMixins,userHasAuthority],
   setup: () => ({ v$: useVuelidate() }),
   data() {
      return {
         items: [{ label: "Product Modal" }],
         selectableCategories: null,
      };
   },
   validations() {
      return {
         input: {
            categoryID: { required },
            name: { required },
            country: { required },
            publishYear: { required },
            brandName: { required },
            description: { required },
         },
      };
   },
   created() {
      this.initFilters1();
      this.index();
      this.getSelectableCategories();
   },
   methods: {
      async index() {
         this.lists = (await this.getMethod("product-modal/all")).data;
         this.loading = false;
      },
      async getSelectableCategories() {
         this.selectableCategories = (
            await this.getMethod("main-category/selectableCategories")
         ).data;
      },
      async store() {
         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.loading = true;
            this.storeMethod("product-modal/register", this.input)
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  this.Dialog = false;
                  this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
      getDetails(id) {
         this.$router.push({
            name: "productmodal_details",
            params: { id: id },
         });
      },
      async update() {
         const isFormCorrect = await this.v$.$validate();

         if (isFormCorrect) {
            this.loading = true;
            this.updateMethod(
               "product-modal/update/" + this.input.id,
               this.input
            )
               .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  this.Dialog = false;
                  this.index();
               })
               .catch((error) => {
                  this.Dialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                  });
               });
         } else {
            this.loading = false;
         }
      },
   },
};
</script>

<style lang="scss">
#button_1 {
   margin: 1px;
   width: auto;
}
#button_2 {
   margin: 1px;
   width: auto;
}
#button_3 {
   margin: 1px;
   width: auto;
}
</style>
