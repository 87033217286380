import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

function getProduct(token) {
  return axios
    .get(apiUrl + "product/allWithStock", {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}

function getProductDetails(id, token) {
  return axios
    .get(apiUrl + "product/" + id, {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}

function storeProduct(token, data) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  const bodyParameter = {
    name: data.name,
    productModalId: data.productModalId,
    standardPrice: data.standardPrice,
    promotionPrice: data.promotionPrice,
    minimumPrice: data.minimumPrice,
    currency: data.currency,
    description: data.description,
    attributes: data.attributes,
  };

  return axios
    .post(
      apiUrl + "product/register",
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function updateProduct(token, data) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  const bodyParameter = {
    name: data.name,
    standardPrice: data.standardPrice,
    promotionPrice: data.promotionPrice,
    minimumPrice: data.minimumPrice,
    currency: data.currency,
    description: data.description,
    attributes: data.attributeDTOList,
  };

  return axios
    .put(
      apiUrl + "product/update/" + data.id,
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function getProductItems(id, token) {
  return axios
    .get(
      apiUrl + "product/item/byProductId/" + id,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}

function getBusinessAll(token) {
  return axios
    .get(apiUrl + "business-sector/all", {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
}

function getBranches(id, token) {
  return axios
    .get(
      apiUrl + "product/branchesWithStock/" + id,
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.data);
}

function changeNotReadyStatus(id, token) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  const bodyParameter = null;

  return axios
    .put(
      apiUrl + "product/item/markNotReady/" + id,
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function changeReadyStatus(id, token) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  const bodyParameter = null;

  return axios
    .put(
      apiUrl + "product/item/markReady/" + id,
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function storeProductItem(id, token, data) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  const bodyParameter = {
    location: data.id,
    serialNumbers: data.serialNumbers,
    productId: id,
  };

  return axios
    .post(
      apiUrl + "product/item",
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function trasnsferProductItem(data, token) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  const bodyParameter = {
    location: data.location,
    productItemId: data.productItemId,
  };
  return axios
    .post(
      apiUrl + "product/item/transfer",
      bodyParameter,
      config
    )
    .then((res) => res.data);
}

function deleteProductItem(id, token) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios
    .delete(apiUrl + "product/item/" + id, config)
    .then((res) => res.data);
}

function deleteProduct(id, token) {
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  return axios
    .delete(
      apiUrl + "product/delete/" + id,
      config
    )
    .then((res) => res.data);
}

export const getProductService = getProduct;
export const getBusinessAllService = getBusinessAll;
export const getBranchesService = getBranches;
export const getProductDetailService = getProductDetails;
export const getProductItemService = getProductItems;
export const changeNotReadyStatusService = changeNotReadyStatus;
export const changeReadyStatusService = changeReadyStatus;
export const storeProductItemService = storeProductItem;
export const trasnsferProductItemService = trasnsferProductItem;
export const deleteProductItemService = deleteProductItem;
export const updateProductService = updateProduct;
export const storeProductService = storeProduct;
export const deleteProductService = deleteProduct;
