<template>
    <div>
        <Toast />
        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>

        <div class="p-d-flex p-jc-center p-mt-4" v-if="!loading">
            <div class="p-col-7 p-fluid card">
                <h4 class="p-text-center p-text-bold">
                    Customer Confirm Order Details
                </h4>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Item Name</div>
                    <div class="p-col-4">{{ order.productItem.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Customer Name</div>
                    <div class="p-col-4">{{ order.customer.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Customer Address</div>
                    <div class="p-col-4">{{ order.customer.address }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Customer Phone</div>
                    <div class="p-col-4">{{ order.customer.phoneNumber }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">quantity</div>
                    <div class="p-col-4">{{ order.quantity }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Deal Price</div>
                    <div class="p-col-4">{{ order.dealPrice }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Downpayment</div>
                    <div class="p-col-4">{{ order.downPayment }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Remark</div>
                    <div class="p-col-4">{{ order.remark }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Stock Type</div>
                    <div class="p-col-4">{{ order.stockType }}</div>
                </div>
                <hr />
                <h4 class="p-text-center p-text-bold">Sold By</h4>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Sales Person</div>
                    <div class="p-col-4">{{ order.salesPerson.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Sales Branch</div>
                    <div class="p-col-4">{{ order.salesBranch.name }}</div>
                </div>
                <hr />

                <div class="p-grid">
                    <Button
                        label="Update Stock Type"
                        class="p-button-rounded p-button-info p-mb-2"
                        @click="updateStockTypeDialog = true"
                    />
                </div>

                <Dialog
                    header="Change Stock Type"
                    v-model:visible="updateStockTypeDialog"
                    :style="{ width: '30vw' }"
                    :modal="true"
                >
                    <div class="p-fluid">
                        <div class="p-field">
                            <label for="description">Stock Type</label>
                            <Dropdown
                                v-model="updateData.stockType"
                                :options="stock_type_lists"
                                optionLabel="name"
                                optionValue="code"
                            />
                        </div>
                    </div>

                    <div v-for="category of this.order.productUnit" :key="category.id" class="field-checkbox p-m-2"> 
                        <Checkbox :id="category.id" name="category" :value="category" v-model="updateData.productUnitDTO" />
                        <label :for="category.id">Serial No - {{category.serialNo}}</label> - 

                        <label :for="category.id">ID - {{category.id}}</label> - 

                        <label :for="category.id">CCO ID - {{category.ccoId}}</label>
                    </div>

                    <template #footer>
                        <Button
                            label="Update"
                            icon="pi pi-check"
                            @click="updateStockType"
                            autofocus
                            class="p-button-text"
                        />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";

export default {
    mixins: [helperMixins, requestMixins],
    data() {
        return {
            order: "",
            home: { icon: "pi pi-home", to: "/" },
            items: [
                { label: "CCO Lists", to: "/customerOrders" },
                { label: "CCO Details" },
            ],
            updateStockTypeDialog: false,
            stock_type_lists: [
                { name: "OUTOFSTOCK", code: "OUTOFSTOCK" },
                { name: "PREORDER", code: "PREORDER" },
                { name: "INSTOCK", code: "INSTOCK" },
            ],
            updateData: {
                stockType: "",
                orderQuantity: "",
                productUnitDTO: ""
            },
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.order = (await this.getMethod("customer-orders/"+this.$route.params.id)).data;
            
            this.updateData.stockType = this.order.stockType;
            this.updateData.orderQuantity = this.order.quantity;

            this.loading = false;
        },
        updateStockType() {
            console.log(this.updateData)
            this.updateMethod("customer-orders/ccoDetails/" + this.$route.params.id, this.updateData)
                .then((data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  this.updateStockTypeDialog = false;
                  this.fetchData();
                })
                .catch((error) => {
                  this.updateStockTypeDialog = false;
                  this.loading = false;
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.response.data.message,
                     life: 3000,
                });
            });
        },
        // checkQty(){
        //     console.log(this.updateData.productUnitDTO.length);
        // },
        // checkDisable(id) {

        //     let category = {
        //         231 : false,
        //         276 : false,
        //         277 : false
        //     };

        //     return category[id];
        // }
    }
};
</script>