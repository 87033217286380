<template>
	<div>
		<Toast />

		<Breadcrumb :home="home" :model="items" />

		<ConfirmDialog></ConfirmDialog>

		<div class="p-grid p-mt-4">
			<div class="p-col-12">
					<div class="card">
						<Toolbar class="p-mb-4">
							<template v-slot:right>
								<Dropdown v-model="selectedStatus" :options="statusLists" optionLabel="status" optionValue="field" placeholder="Select a Status" @change="onToggle" style="width: 20em"/>
							</template>
						</Toolbar>

						<DataTable 
							:value="lists" 
							:paginator="true" 
							:rows="25" 
							:loading="loading"
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							:rowsPerPageOptions="[10,25,50]" 
							responsiveLayout="scroll" 
							:sortOrder="1" 
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							v-model:filters="filters1" filterDisplay="menu" 
							:globalFilterFields="['id','name','abbreviation','type']" >
							<template #header>
								<div class="p-d-flex p-jc-end">
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText  v-model="filters1['global'].value" placeholder="Search" />
									</span>
								</div>
							</template>
							<Column field="id" header="ID"></Column>
							<Column field="dealPrice" header="Deal Price"></Column>
							<Column field="downPayment" header="Down Payment"></Column>
							<Column field="createdBy" header="Created By"></Column>
							<Column field="status" header="Status"></Column>
							<Column header="Actions">
								<template #body="{data}">
									<Button label="View" icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="getDetails(data.id)" iconPos="right" />
								</template>						
							</Column>
						</DataTable>				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { requestMixins } from '../../mixins/requestMixins';
import { helperMixins } from '../../mixins/helperMixins';

export default {
	mixins: [requestMixins,helperMixins],
	data() {
		return {
            items: [
                {label: 'Enquiry'},
            ],
			statusLists: null,
			selectedStatus: null,
		}
	},
	created() {
        this.initFilters1();
		this.index(1);

		this.statusLists = [
            {field: 1, status: 'New'},
            {field: 2, status: 'Completed'},
            {field: 3, status: 'Investigating'},
            {field: 4, status: 'Canceled'},
            {field: 5, status: 'Deleted'},
        ];
    },
    methods: {
		async index(status) {

			this.lists = (
				await this.getMethodWithParams('enquiry/all', "status", status)
			).data
			this.loading = false;
		},
        getDetails(id) {
			this.$router.push({  name: 'enquiryDetails', params: { id: id }})
		},
		async onToggle(){
			this.index(this.selectedStatus);
			}
    },
}
</script>

<style lang="scss">
#button_1 {
	margin: 2px;
    width: auto;
}
#button_2 {
	margin: 2px;
    width: auto;
}
</style>