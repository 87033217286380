<template>
	<div>
		<Toast />

		<Breadcrumb :home="home" :model="items" />

		<ConfirmDialog></ConfirmDialog>

		<div class="p-grid p-mt-4">
			<div class="p-col-12">
				<div class="card">
					<DataTable :value="lists" :paginator="true" :rows="25" :loading="loading"
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						:rowsPerPageOptions="[10,25,50]" responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						v-model:filters="filters1" filterDisplay="menu" :globalFilterFields="['id','name','abbreviation','type']" >
						<template #header>
							<div class="p-d-flex p-jc-between">
								<Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined" @click="clearFilter1()"/>
								<span class="p-input-icon-left">
									<i class="pi pi-search" />
									<InputText v-model="filters1['global'].value" placeholder="Search" />
								</span>
							</div>
						</template>
						<Column field="id" header="ID"></Column>
						<Column field="name" header="Name"></Column>
						<Column field="abbreviation" header="Abbreviation"></Column>
						<Column field="type" header="Type"></Column>
						<Column header="Actions">
							<template #body="{data}">
								<Button id="button_1"  label="View" icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="getDetails(data.id)" iconPos="right" />
							</template>						
						</Column>
					</DataTable>				
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { requestMixins } from '../../mixins/requestMixins';
import { helperMixins } from '../../mixins/helperMixins';

export default {
	mixins: [requestMixins,helperMixins],
	data() {
		return {
            items: [
                {label: 'Business Sectors'},
            ]
		}
	},
	created() {
        this.initFilters1();
		this.index();
    },
    methods: {
		async index() {
			this.lists = (
				await this.getMethod('business-sector/all')
			).data
			this.loading = false;
		},
        getDetails(id) {
			this.$router.push({  name: 'business_sectors_details', params: { id: id }})
		}   
    },
}
</script>

<style lang="scss">
#button_1 {
	margin: 2px;
    width: auto;
}
</style>