<template>
   <div>
      <Toast />
      <Breadcrumb :home="home" :model="items" />

      <div class="p-d-flex p-jc-center" v-if="loading">
         <ProgressSpinner />
      </div>
      <ConfirmDialog></ConfirmDialog>
      <div class="p-grid p-mt-4" v-if="!loading">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:right>
                     <Button
                        label="Add New"
                        icon="pi pi-plus"
                        class="p-button-primary p-mr-2"
                        @click="createPreOrder"
                        :disabled="!hasAuthorities(['AGM1','PRO2'])"
                     />
                  </template>
                  <template v-slot:left>
                     <Button
                        icon="pi pi-external-link"
                        label="Download"
                        @click="exportCSV($event)"
                     />
                  </template>
               </Toolbar>

               <DataTable
                  ref="dt"
                  :value="data"
                  :paginator="true"
                  :rows="10"
                  showGridlines
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  :rowsPerPageOptions="[10, 20, 50]"
                  responsiveLayout="scroll"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'orderNo',
                     'productName',
                     'quantity',
                     'cco',
                     'arrivalStatus',
                     'orderDate',
                     'arrivalDate',
                     'arrivedDate',
                  ]"
               >
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column
                     field="orderNo"
                     header="Order No"
                     style="min-width: 13rem"
                  ></Column>
                  <Column
                     field="productName"
                     header="Product"
                     style="min-width: 5rem"
                  ></Column>
                  <Column
                     field="quantity"
                     header="Quantity"
                     style="min-width: 1rem"
                  ></Column>
                  <Column
                     field="cco"
                     header="CCO"
                     style="min-width: 1rem"
                  ></Column>
                  <Column
                     field="arrivalStatus"
                     header="Status"
                     style="min-width: 5rem"
                  ></Column>
                  <Column
                     field="orderDate"
                     header="Order Date"
                     style="min-width: 8rem"
                  ></Column>
                  <Column
                     field="arrivalDate"
                     header="Est Arrival Date"
                     style="min-width: 8rem"
                  ></Column>
                  <Column
                     field="arrivedDate"
                     header="Arrived Date"
                     style="min-width: 8rem"
                  ></Column>
                  <Column header="Action" style="min-width: 25rem">
                     <template #body="{ data }">
                        <Button
                           id="button_1"
                           :disabled="data.arrivalStatus != 'PENDING' || !hasAuthorities(['AGM1','PRO2'])"
                           label="Receive"
                           icon="pi pi-check-circle"
                           class="p-button-rounded p-button-success p-preOrder"
                           @click="openReceiveDialog(data.id)"
                           iconPos="right"
                        />
                        <Button
                           id="button_2"
                           :disabled="data.arrivalStatus === 'CANCELLED'"
                           label="Update"
                           icon="pi pi-pencil"
                           class="p-button-rounded p-button-warning p-preOrder"
                           @click="
                              data.arrivalStatus === 'PENDING'
                                 ? editPreOrder(data.id)
                                 : editArrivedDate(data)
                              ||
                              !hasAuthorities(['AGM1','PRO2'])
                           "
                           iconPos="right"
                        />
                        <Button
                           id="button_3"
                           :disabled="data.arrivalStatus != 'PENDING' || !hasAuthorities(['AGM1','PRO2'])"
                           label="Cancel"
                           icon="pi pi-times"
                           class="p-button-rounded p-button-danger p-preOrder"
                           @click="cancelPreOrder(data.id)"
                           iconPos="right"
                        />
                     </template>
                  </Column>
               </DataTable>
            </div>

            <Dialog
               header="Add Purchase Order"
               v-model:visible="createPreOrderDialog"
               :style="{ width: '30vw' }"
               :modal="true"
            >
               <div class="p-fluid">
                  <div class="p-field">
                     <label for="description">Product</label>
                     <Dropdown
                        v-model="pre_order.productId"
                        :options="products_lists"
                        :disabled="editStatus"
                        optionLabel="productItemName"
                        optionValue="productItemID"
                        placeholder="--- Select One ---"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Quantity</label>
                     <InputNumber
                        v-model="pre_order.quantity"
                        :useGrouping="false"
                     />
                  </div>

                  <div class="p-field">
                     <label for="basic">Order Date</label>
                     <Calendar
                        id="basic"
                        v-model="pre_order.orderDate"
                        :maxDate="maxDate"
                        :manualInput="false"
                        :showIcon="true"
                        dateFormat="yy-mm-dd"
                        :showButtonBar="true"
                        :monthNavigator="true" :yearNavigator="true" yearRange="1990:2030"
                     />
                  </div>

                  <div class="p-field">
                     <label for="basic">Estimate Arrival Date</label>
                     <Calendar
                        id="basic"
                        v-model="pre_order.arrivalDate"
                        :minDate="maxDate"
                        :manualInput="false"
                        :showIcon="true"
                        dateFormat="yy-mm-dd"
                        :showButtonBar="true"
                        :monthNavigator="true" :yearNavigator="true" yearRange="1990:2030"
                     />
                  </div>

                  <div class="p-field">
                     <label for="type">Remark</label>
                     <InputText type="text" v-model="pre_order.remark" />
                  </div>
               </div>
               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     @click="closePreOrderDialog"
                     class="p-button-text"
                  />

                  <Button
                     v-if="!editStatus"
                     label="Create"
                     icon="pi pi-check"
                     @click="storePreOrder"
                     autofocus
                     class="p-button-text"
                  />

                  <Button
                     v-else
                     label="Update"
                     icon="pi pi-check"
                     @click="updatePreOrder"
                     autofocus
                     class="p-button-text"
                  />
               </template>
            </Dialog>

            <Dialog
               header="Receive Purchase Order"
               v-model:visible="receivePreOrderDialog"
               :style="{ width: '30vw' }"
               :modal="true"
            >
               <div class="p-fluid">
                  <div class="p-field">
                     <label for="basic">Arrived Date</label>
                     <Calendar
                        id="basic"
                        v-model="receive_order.arrivedDate"
                        :manualInput="false"
                        :showIcon="true"
                        :dateFormat="yy - mm - dd"
                        @date-select="changeDateformat"
                        :showButtonBar="true"
                        :monthNavigator="true" :yearNavigator="true" yearRange="1990:2030"
                     />
                  </div>

                  <div class="p-field">
                     <Dropdown
                        v-model="receive_order.location"
                        :options="business_sector_all"
                        optionLabel="name"
                        optionValue="id"
                        placeholder="--- Select One ---"
                     >
                        <template #option="slotProps">
                           <span>{{ slotProps.option.name }}</span>
                           (<span>{{ slotProps.option.abbreviation }}</span
                           >)
                        </template>
                     </Dropdown>
                  </div>

                  <div class="p-field">
                     <div
                        class="p-field p-mt-2"
                        v-for="(serial, k) in receive_order.serialNumbers"
                        :key="k"
                     >
                        <label for="name">Serial No</label>
                        <InputText
                           type="text"
                           v-model="receive_order.serialNumbers[k]"
                        />
                     </div>
                  </div>
               </div>
               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     @click="closePreOrderDialog"
                     class="p-button-text"
                  />

                  <Button
                     label="Update"
                     icon="pi pi-check"
                     @click="receivePreOrder"
                     autofocus
                     class="p-button-text"
                  />
               </template>
            </Dialog>

            <Dialog
               header="Edit Arrived Date"
               v-model:visible="editArrivedDateDialog"
               :style="{ width: '30vw' }"
               :modal="true"
            >
               <div class="p-fluid">
                  <div class="p-field" id="calendar">
                     <label for="basic">Estimate Arrival Date</label>
                     <Calendar
                        id="basic"
                        v-model="edit_arrived_date.arrivedDate"
                        :showIcon="true"
                        dateFormat="yy-mm-dd"
                        :showButtonBar="true"
                        :monthNavigator="true" :yearNavigator="true" yearRange="1990:2030"
                     />
                  </div>
               </div>
               <template #footer>
                  <Button
                     label="Cancel"
                     icon="pi pi-times"
                     @click="closePreOrderDialog"
                     class="p-button-text"
                  />
                  <Button
                     label="Update"
                     icon="pi pi-check"
                     @click="updateArrivedDate"
                     autofocus
                     class="p-button-text"
                  />
               </template>
            </Dialog>
         </div>
      </div>
   </div>
</template>

<script>
import { 
   receiveService,
   storeService,
   updateService,
   cancelService,
} from "../../service/preOrderService";
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";
import userHasAuthority from "../../mixins/userHasAuthority";

export default {
   mixins: [requestMixins, helperMixins,userHasAuthority],
   data() {
      return {
         home: { icon: "pi pi-home", to: "/" },
         items: [{ label: "Purchase Order" }],
         token: this.$store.getters.getToken,
         loading: true,
         editStatus: false,
         data: "",
         business_sector_all: "",
         products_lists: "",
         createPreOrderDialog: false,
         receivePreOrderDialog: false,
         editArrivedDateDialog: false,
         pre_order: {
            id: "",
            arrivalDate: "",
            orderDate: "",
            productId: "",
            quantity: "",
            remark: "",
         },
         receive_order: {
            id: "",
            arrivedDate: "",
            location: "",
            serialNumbers: [],
         },
         edit_arrived_date: {},
      };
   },
   created() {
      this.fetchPreOrder();
      this.fetchProduct();
      this.fetchBusinessAll();
      this.initFilters1();
   },
   methods: {
      async fetchPreOrder() {
         this.data = (await this.getMethod("preOrder/all")).data;
         this.loading = false;
      },
      async fetchProduct() {
         this.products_lists = (await this.getMethod("product/allWithStock")).data;
      },
      async fetchBusinessAll() {
         this.business_sector_all = (await this.getMethod("business-sector/all")).data;
      },
      createPreOrder() {
         this.createPreOrderDialog = true;
         this.editStatus = false;
      },
      openReceiveDialog(preOrder_id) {
         const result = this.data.find(({ id }) => id === preOrder_id);

         this.receive_order.id = preOrder_id;
         this.receive_order.arrivedDate = result.arrivalDate;

         for (var i = 0; i < result.quantity; i++) {
            this.receive_order.serialNumbers.push("");
         }

         this.receivePreOrderDialog = true;
      },
      receivePreOrder() {
         (this.loading = true),
            receiveService(this.token, this.receive_order).then(
               (data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  (this.receivePreOrderDialog = false), this.fetchPreOrder();
               },
               (error) => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Sorry, Serial number already exit!",
                     detail: error.message,
                     life: 3000,
                  });
               }
            );
      },
      storePreOrder() {
         (this.loading = true),
            storeService(this.token, this.pre_order).then(
               (data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  (this.createPreOrderDialog = false), this.fetchPreOrder();
               },
               (error) => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Something Wrong",
                     detail: error.message,
                     life: 3000,
                  });
               }
            );
      },
      editPreOrder(preOrder_id) {
         const result = this.data.find(({ id }) => id === preOrder_id);

         this.pre_order.id = preOrder_id;
         this.pre_order.arrivalDate = result.arrivalDate;
         this.pre_order.orderDate = result.orderDate;
         this.pre_order.productId = result.productId;
         this.pre_order.remark = result.remark;
         this.pre_order.quantity = result.quantity;

         this.editStatus = true;
         this.createPreOrderDialog = true;
      },
      updatePreOrder() {
         (this.loading = true),
            updateService(this.token, this.pre_order).then(
               (data) => {
                  this.$toast.add({
                     severity: "success",
                     summary: "Success",
                     detail: data.message,
                     life: 3000,
                  });
                  (this.createPreOrderDialog = false), this.fetchPreOrder();
               },
               (error) => {
                  this.$toast.add({
                     severity: "error",
                     summary: "Serial number already exit!",
                     detail: error.message,
                     life: 3000,
                  });
               }
            );
      },
      editArrivedDate(data) {
         console.log(data);
         this.editArrivedDateDialog = true;

         this.edit_arrived_date.id = data.id;
         this.edit_arrived_date.arrivedDate = data.arrivedDate;
      },
      updateArrivedDate() {
         this.edit_arrived_date.arrivedDate = this.formatDate(
            this.edit_arrived_date.arrivedDate
         );

         this.loading = true;
         this.updateMethod("preOrder/arrivedDate", this.edit_arrived_date)
            .then((data) => {
               this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: data.message,
                  life: 3000,
               });
               this.editArrivedDateDialog = false;
               this.fetchPreOrder();
            })
            .catch((error) => {
               this.editArrivedDateDialog = false;
               this.loading = false;
               this.$toast.add({
                  severity: "error",
                  summary: "Something Wrong",
                  detail: error.response.data.message,
                  life: 3000,
               });
            });
      },
      cancelPreOrder(id) {
         this.$confirm.require({
            message: "Are you sure you want to Procced?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => {
               (this.loading = true),
                  cancelService(id, this.token).then(
                     (data) => {
                        this.$toast.add({
                           severity: "success",
                           summary: "Success",
                           detail: data.message,
                           life: 3000,
                        }),
                           this.fetchPreOrder();
                     },
                     (error) => {
                        console.log(error);
                     }
                  );
            },
            reject: () => {
               this.$toast.add({
                  severity: "error",
                  summary: "Rejected",
                  detail: "You have rejected",
                  life: 3000,
               });
            },
         });
      },
      changeDateformat() {
         this.edit_arrived_date.arrivedDate = this.formatDate(
            this.edit_arrived_date.arrivedDate
         );
      },
      formatDate(date) {
         var d = new Date(date),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear();

         if (month.length < 2) month = "0" + month;
         if (day.length < 2) day = "0" + day;

         return [year, month, day].join("-");
      },
   },
};
</script>

<style lang="scss">
#button_1 {
   margin: 2px;
   width: auto;
}
#button_2 {
   margin: 2px;
   width: auto;
}
#button_3 {
   margin: 2px;
   width: auto;
}
#calendar {
   height: 150px;
}
</style>