import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

function index(token) {
   return axios
      .get(apiUrl + "preOrder/all", {
         headers: {
            Authorization: token,
            "Content-Type": "application/json",
         },
      })
      .then((res) => res.data);
}

function store(token, data) {
   const config = {
      headers: {
         Authorization: token,
         "Content-Type": "application/json",
      },
   };

   const bodyParameter = {
      arrivalDate: data.arrivalDate,
      orderDate: data.orderDate,
      productId: data.productId,
      quantity: data.quantity,
      remark: data.remark,
   };

   return axios
      .post(
         apiUrl + "preOrder/register",
         bodyParameter,
         config
      )
      .then((res) => res.data);
}

function receive(token, data) {
   const config = {
      headers: {
         Authorization: token,
         "Content-Type": "application/json",
      },
   };

   const bodyParameter = {
      id: data.id,
      arrivedDate: data.arrivedDate,
      location: data.location,
      serialNumbers: data.serialNumbers,
   };

   return axios
      .post(
         apiUrl + "preOrder/receive",
         bodyParameter,
         config
      )
      .then((res) => res.data);
}

function update(token, data) {
   const config = {
      headers: {
         Authorization: token,
         "Content-Type": "application/json",
      },
   };

   const bodyParameter = {
      id: data.id,
      arrivalDate: data.arrivalDate,
      orderDate: data.orderDate,
      quantity: data.quantity,
      remark: data.remark,
   };

   return axios
      .put(
         apiUrl + "preOrder/update",
         bodyParameter,
         config
      )
      .then((res) => res.data);
}

function cancel(id, token) {
   const config = {
      headers: {
         Authorization: token,
         "Content-Type": "application/json",
      },
   };

   const bodyParameter = {};

   return axios
      .put(
         apiUrl + "preOrder/cancel/" + id,
         bodyParameter,
         config
      )
      .then((res) => res.data);
}

export const GetListService = index;
export const storeService = store;
export const receiveService = receive;
export const updateService = update;
export const cancelService = cancel;
