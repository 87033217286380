<template>
    <div>
        <Toast />

        <Breadcrumb :home="home" :model="items" />

        <div class="p-d-flex p-jc-center" v-if="loading">
            <ProgressSpinner />
        </div>

        <div class="p-d-flex p-jc-center p-mt-4" v-if="!loading">
            <div class="p-col-7 p-fluid card">
                <h4 class="p-text-center p-text-bold">
                    Enquiry Details
                </h4>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Item Name</div>
                    <div class="p-col-4">{{ enquiryDetails.productItem.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Customer Name</div>
                    <div class="p-col-4">{{ enquiryDetails.customer.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">quantity</div>
                    <div class="p-col-4">{{ enquiryDetails.orderQuantity }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Deal Price</div>
                    <div class="p-col-4">{{ enquiryDetails.dealPrice }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Downpayment</div>
                    <div class="p-col-4">{{ enquiryDetails.downPayment }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Remark</div>
                    <div class="p-col-4">{{ enquiryDetails.remark }}</div>
                </div>
                <hr />
                <h4 class="p-text-center p-text-bold">Sold By</h4>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Sales Person</div>
                    <div class="p-col-4">{{ enquiryDetails.salesPerson.name }}</div>
                </div>
                <hr />
                <div class="p-grid">
                    <div class="p-col-4 p-text-bold">Sales Branch</div>
                    <div class="p-col-4">{{ enquiryDetails.salesBranch.name }}</div>
                </div>
                <hr />
            </div>
        </div>
    </div>
</template>

<script>
import { helperMixins } from "../../mixins/helperMixins";
import { requestMixins } from "../../mixins/requestMixins";

export default {
    mixins: [helperMixins, requestMixins],
    data() {
        return {
            enquiryDetails: "",
            home: { icon: "pi pi-home", to: "/" },
            items: [
                { label: "Enquiry Lists", to: "/enquiry" },
                { label: "Enquiry Details" },
            ],
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.enquiryDetails = (await this.getMethod("mobile/enquiry/"+ this.$route.params.id)).data;

            this.loading = false;
        },
    }
};
</script>