import { FilterMatchMode } from "primevue/api";

export const helperMixins = {
   data() {
      return {
         home: { icon: "pi pi-home", to: "/" },
         lists: null,
         loading: true,
         editStatus: false,
         Dialog: false,
         filters1: null,
         input: {},
      };
   },
   computed: {
      buttonLable() {
         return this.editStatus ? "Update" : "Store";
      },
   },
   methods: {
      edit(data) {
         console.log(data);
         this.input = { ...data };
         this.Dialog = true;
         this.editStatus = true;
         this.$nextTick(() => {
            this.v$.$reset();
         });
      },
      openDialog() {
         this.Dialog = true;
         this.editStatus = false;
         this.input = {};
         this.$nextTick(() => {
            this.v$.$reset();
         });
      },
      closeDialog() {
         this.Dialog = false;
      },
      initFilters1() {
         this.filters1 = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
         };
      },
      clearFilter1() {
         this.initFilters1();
      },
      exportCSV() {
         this.$refs.dt.exportCSV();
      },
   },
};
