import axios from "axios";

const apiUrl = process.env.VUE_APP_API_URL;

function details(id) { 

    return axios.get(apiUrl + 'product-modal/' + id)
    .then(res => res.data)
}
function getPhotos(id) { 

    return axios.get(apiUrl + 'product/photo/all/' + id)
    .then(res => res.data)
}

function storePhotos(data,id) {
	
	const bodyParameter = {
		files: data,
	}
    return axios.post(apiUrl + 'product/photo/uploadMultipleFiles/' + id , bodyParameter)
    .then(res => res.data)
}

function getDocuments(id) { 

    return axios.get(apiUrl + 'product/document/all/' + id)
    .then(res => res.data)
}

function getSpecification(id) { 

    return axios.get(apiUrl + 'product/specification/byProductId/' + id)
    .then(res => res.data)
}

function storeSpecification(token,id,data) { 

	const config = {
		headers: {
			'Authorization': token,
			'Content-Type': 'application/json'
		}
	}
    return axios.post(apiUrl + 'product/specification/byProductId/' + id, data, config)
    .then(res => res.data)

}

function getAttribute(id) { 

    return axios.get(apiUrl + 'product/attribute/byProductModal/' + id )
    .then(res => res.data)
}

function getAttributebyProduct(id) {

	return axios.get(apiUrl + 'product/attribute/byProduct/' + id )
}

function storeAttribute(id,data) { 

	const bodyParameter = {
		attributeList: data,
		productID: id
	}
    return axios.post(apiUrl + 'attribute/register', bodyParameter)
    .then(res => res.data)

}

function updateAttribute(data) { 

	const bodyParameter = {
        id: data.attributeID,
        name: data.attributeName,
        filter: data.filter,
        remark: data.remark
	}
    return axios.put(apiUrl + 'product/attribute/update', bodyParameter)
    .then(res => res.data)

}

function deleteAttribute(id,token) { 
	const config = {
		headers: {
			'Authorization': token,
			'Content-Type': 'application/json'
		}
	}

	return axios.delete(apiUrl + 'attribute/delete/'+ id, config)
    .then(res => res.data)
}



export const GetDetailService = details;
export const GetPhotoService = getPhotos;
export const StorePhotoService = storePhotos;
export const GetDocumentService = getDocuments;
export const getSpecificationService = getSpecification;
export const storeSpecificationService = storeSpecification;
export const storeAttributeService = storeAttribute;
export const getAttributeService = getAttribute;
export const getAttributebyProductService = getAttributebyProduct;
export const updateAttributeService = updateAttribute;
export const deleteAttributeService = deleteAttribute;