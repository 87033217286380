<template>
	<div>
		<Breadcrumb :home="home" :model="items" />

		<div class="p-d-flex p-jc-center">
			<div class="p-col-7 p-fluid card">
				<div class="p-fluid">
					<div class="p-field">
						<label for="name">Name</label>
						<InputText id="name" type="text" placeholder="Name" v-model="input.name" />
					</div>
					<div class="p-field">
						<label for="description">Description</label>
						<Editor editorStyle="height: 320px" v-model="input.description" >
							<template #toolbar>
								<span class="ql-formats">
									<button class="ql-bold"></button>
									<button class="ql-italic"></button>
									<button class="ql-underline"></button>
									<button class="ql-list" value="ordered" type="button"></button>
								</span>
							</template>
						</Editor>
					</div>
					<div class="p-grid p-jc-center">
						<div class="p-col-5">				
							<Button label="Cancel" class="p-button-rounded p-button-secondary p-mb-2" @click="back()" />
						</div>
						<div class="p-col-5">
							<Button label="Save" class="p-button-rounded p-mb-2" @click="store()" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { requestMixins } from "../../mixins/requestMixins";

	export default {
		mixins: [requestMixins],
		data() {
            return {
				home: {icon: 'pi pi-home', to: '/'},
				items: [
					{label: 'Terms & Condition', to: '/termandconditions'},
					{label: 'Create'},				
				],
                input: {
                    name: "",
					description: ""

                }
            }
        },
        methods: {
            store() {
				this.storeMethod("term-condition", this.input)
					.then(() => {
						this.$router.push({ name: 'tandclists', query: {msg: 'created'}})
					})
					.catch((error) => {
						console.log(error)
					})
            },
			back() {
				this.$router.back()
			}
        }
	}
</script>

<style scoped>

</style>