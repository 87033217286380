import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const store = createStore({
  state () {
    return {
      is_auth: false,
      token: "",
      authorityList: ""
    }
  },
  getters: {
    getAuth (state) {
      return state.is_auth
    },
    getToken (state) {
      return state.token
    },
    getAuthorities (state) {
      return state.authorityList
    }
  },
  mutations: {
    storeToken (state, token) {
      state.token = 'Bearer ' + token;
      state.is_auth = true;
    },
    clearToken (state) {
      state.token = "";
      state.is_auth = false;
    },
    storeAuthorityList (state, authorityList) {
      state.authorityList = authorityList;
    },
  },
  actions: {
    login ( context, token) {
      context.commit('storeToken', token)
    },
    storeAuthorityLists ( context, authorityLists) {
      context.commit('storeAuthorityList', authorityLists)
    },
    logout ({ commit }) {
      commit('clearToken')
    }
  },
  plugins: [createPersistedState()]
})

export default store;