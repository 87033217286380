<template>
   <div>
      <Toast />

      <Breadcrumb :home="home" :model="items" />

      <ConfirmDialog></ConfirmDialog>

      <div class="p-grid p-mt-4">
         <div class="p-col-12">
            <div class="card">
               <Toolbar class="p-mb-4">
                  <template v-slot:left>
                     <h4>Product Transfer History by Business Sector</h4>
                  </template>
                  <!-- <template v-slot:right>
                     <Dropdown
                        v-model="business_sector_id"
                        :options="businessSecotrsLists"
                        optionLabel="name"
                        optionValue="id"
                        @change="index"
                        placeholder="--- Select One ---"
                     />
                  </template> -->
               </Toolbar>
               <DataTable
                  :value="product_items_transfer"
                  showGridlines
                  responsiveLayout="scroll"
                  v-model:filters="filters1"
                  filterDisplay="menu"
                  :globalFilterFields="[
                     'productModal',
                     'serialNo',
                     'createdBy',
                     'sourceFrom',
                     'destinedTo'
                  ]"
               >
                  <template #empty> No History Found. </template>
                  <template #loading>
                     Loading Transfer History data. Please wait.
                  </template>
                  <template #header>
                     <div class="p-d-flex p-jc-between">
                        <Button
                           type="button"
                           icon="pi pi-filter-slash"
                           label="Clear"
                           class="p-button-outlined"
                           @click="clearFilter1()"
                        />
                        <span class="p-input-icon-left">
                           <i class="pi pi-search" />
                           <InputText
                              v-model="filters1['global'].value"
                              placeholder="Search"
                           />
                        </span>
                     </div>
                  </template>
                  <Column field="productModal" header="Product Modal"></Column>
                  <Column field="serialNo" header="Serial No"></Column>
                  <Column field="transferDate" header="Transfer Date"></Column>
                  <Column field="sourceFrom" header="From"></Column>
                  <Column field="destinedTo" header="To"></Column>
                  <Column field="createdBy" header="Created By"></Column>
               </DataTable>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { requestMixins } from "../mixins/requestMixins";
import { helperMixins } from "../mixins/helperMixins";

export default {
   mixins: [requestMixins, helperMixins],
   data() {
      return {
         items: [{ label: "Product Transfer History" }],
         product_items_transfer: null,
         // businessSecotrsLists: null,
      };
   },
   created() {
      this.initFilters1();
      // this.getBusinessSector();
      this.index();
   },
   methods: {
      async index() {
         this.product_items_transfer = (
            await this.getMethod(
               "product-modal/productTransferHistory/all"
            )
         ).data;
      },
      // async getBusinessSector() {
      //    this.businessSecotrsLists = (
      //       await this.getMethod("business-sector/all")
      //    ).data;
      // },
   },
};
</script>