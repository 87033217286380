import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Login from "../Login.vue";
import Dashboard from "../components/Dashboard.vue";
import Role from "../components/Role/Role.vue";
//import Permissions from "../components/Permission/Permissions.vue";
import Employees from "../components/Employee/Employees.vue";
import EmployeeDetails from "../components/Employee/EmployeeDetails.vue";
//import Locations from "../components/Location/Locations.vue";
import BusinessSectors from "../components/BusinessSector/BusinessSectors.vue";
import BusinessSectorsDetails from "../components/BusinessSector/BusinessSectorsDetails.vue";
import ProductCategories from "../components/ProductCategories/ProductCategories";
import ProductModals from "../components/ProductModals/ProductModals";
import ProductModalLists from "../components/ProductModals/ProductModalLists";
import ProductModalDetails from "../components/ProductModals/ProductModalDetails";
import Products from "../components/Product/Products.vue";
import ProductLists from "../components/Product/ProductLists.vue";
import ProductDetails from "../components/Product/ProductDetails.vue";
import PreOrders from "../components/PreOrders/PreOrders.vue";
import CCO from "../components/CCO/CCO.vue";
import CCOLists from "../components/CCO/CCOLists.vue";
import CCODetails from "../components/CCO/CCODetails.vue";
import Deliveries from "../components/Deliveries/Deliveries.vue";
import DeliveriesList from "../components/Deliveries/DeliveriesList.vue";
import DeliveriesDetails from "../components/Deliveries/DeliveriesDetails.vue";
import TermandConditions from "../components/TermsAndCondition/TermandConditions";
import TandCLists from "../components/TermsAndCondition/TandCLists";
import TandCCreate from "../components/TermsAndCondition/TandCCreate";
import TandCUpdate from "../components/TermsAndCondition/TandCUpdate";
import PrdouctTransferHistory from "../components/PrdouctTransferHistory";
import EnquiryLists from "../components/Enquiry/EnquiryLists";
import EnquiryDetails from "../components/Enquiry/EnquiryDetails";
import NotFound from "../components/NotFound.vue";

const routes = [
   {
      path: "/",
      name: "home",
      component: Home,
      children: [
         {
            path: "/",
            name: "dashboard",
            component: Dashboard,
            meta: { requiresAuth: true },
         },
         {
            path: "/roles",
            name: "roles",
            component: Role,
            meta: { requiresAuth: true },
         },
         // {
         //    path: "/locations",
         //    name: "locations",
         //    component: Locations,
         //    meta: { requiresAuth: true },
         // },
         // {
         //    path: "/permissions",
         //    name: "permissions",
         //    component: Permissions,
         //    meta: { requiresAuth: true },
         // },
         {
            path: "/employees",
            name: "employees",
            component: Employees,
            meta: { requiresAuth: true },
         },
         {
            path: "/employees/:id",
            name: "employees_details",
            component: EmployeeDetails,
            meta: { requiresAuth: true },
         },
         {
            path: "/business_sectors",
            name: "business_sectors",
            component: BusinessSectors,
            meta: { requiresAuth: true },
         },
         {
            path: "/business_sectors/:id",
            name: "business_sectors_details",
            component: BusinessSectorsDetails,
            meta: { requiresAuth: true },
         },

         // End Admin

         // Sales
         {
            path: "/productcategories",
            name: "productcategories",
            component: ProductCategories,
            meta: { requiresAuth: true },
         },
         {
            path: "/productModals",
            name: "productmodals",
            component: ProductModals,
            children: [
               {
                  path: "",
                  name: "productmodal_lists",
                  component: ProductModalLists,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/productModals/:id",
                  name: "productmodal_details",
                  component: ProductModalDetails,
                  meta: { requiresAuth: true },
               },
            ],
         },
         {
            path: "/products",
            name: "products",
            component: Products,
            children: [
               {
                  path: "",
                  name: "product_lists",
                  component: ProductLists,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/products/:id",
                  name: "product_details",
                  component: ProductDetails,
                  meta: { requiresAuth: true },
               },
            ],
         },
         {
            path: "/purchaseOrder",
            name: "pre_orders",
            component: PreOrders,
            meta: { requiresAuth: true },
         },
         {
            path: "/productTransferHistory",
            name: "productTrasnferHistory",
            component: PrdouctTransferHistory,
            meta: { requiresAuth: true },
         },
         {
            path: "/customerOrders",
            name: "cco_orders",
            component: CCO,
            children: [
               {
                  path: "",
                  name: "cco_orders_lists",
                  component: CCOLists,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/customerOrders/:id",
                  name: "cco_orders_details",
                  component: CCODetails,
                  meta: { requiresAuth: true },
               },
            ],
         },
         {
            path: "/enquiry",
            name: "enquiry",
            component: EnquiryLists,
            meta: { requiresAuth: true },
         },
         {
            path: "/enquiry/:id",
            name: "enquiryDetails",
            component: EnquiryDetails,
            meta: { requiresAuth: true },
         },
         {
            path: "/deliveries",
            name: "deliveries",
            component: Deliveries,
            children: [
               {
                  path: "",
                  name: "deliverieslist",
                  component: DeliveriesList,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/deliveries/:id",
                  name: "deliveriesdetails",
                  component: DeliveriesDetails,
                  meta: { requiresAuth: true },
               },
            ],
         },
         {
            path: "/termandconditions",
            name: "termandconditions",
            component: TermandConditions,
            children: [
               {
                  path: "",
                  name: "tandclists",
                  component: TandCLists,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/termandconditions/create",
                  name: "tandccreate",
                  component: TandCCreate,
                  meta: { requiresAuth: true },
               },
               {
                  path: "/termandconditions/:id",
                  name: "tandcupdate",
                  component: TandCUpdate,
                  meta: { requiresAuth: true },
               },
            ],
         },
         // End Sales
      ],
   },
   {
      path: "/login",
      name: "login",
      component: Login,
      meta: { requiresVisitor: true },
   },
   {
      path: "/:pathMatch(.*)*",
      name: "NotFound",
      component: NotFound,
   },
];

const router = createRouter({
   history: createWebHistory(),
   routes,
});

export default router;
