<template>
    <div class="p-col-6 p-md-6 p-fluid">
        <div class="card">
            <h3>Login Form</h3>
            <transition-group name="p-message" tag="div">
                <Message
                    v-for="msg of messages"
                    :severity="msg.severity"
                    :key="msg.id"
                    >{{ msg.content }}</Message
                >
            </transition-group>
            <div class="p-field p-mt-2">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-user"></i>
                    </span>
                    <InputText
                        placeholder="Username"
                        v-model="input.userName"
                        :class="{
                            'p-invalid':
                                error_status || v$.input.userName.$error,
                        }"
                    />
                </div>
                <small v-if="v$.input.userName.$error" class="p-error">
                    <p
                        v-for="error of v$.input.userName.$errors"
                        :key="error.$uid"
                    >
                        {{ error.$message.replace("Value", "Username") }}
                    </p>
                </small>
            </div>
            <div class="p-field">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <Password
                        @keyup.enter="login"
                        :feedback="false"
                        v-model="input.password"
                        :class="{
                            'p-invalid':
                                error_status || v$.input.password.$error,
                        }"
                        placeholder="Password"
                        toggleMask
                    ></Password>
                </div>
                <small v-if="v$.input.password.$error" class="p-error">
                    <p
                        v-for="error of v$.input.password.$errors"
                        :key="error.$uid"
                    >
                        {{ error.$message.replace("Value", "Password") }}
                    </p>
                </small>
            </div>
            <div class="p-grid">
                <div class="p-col-5">
                    <Button
                        label="Cancel"
                        class="p-button-rounded p-button-secondary p-mb-2"
                    />
                </div>
                <div class="p-col-7">
                    <Button
                        label="Login"
                        class="p-button-rounded p-mb-2"
                        @click="login"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { requestMixins } from "./mixins/requestMixins";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
    name: "Login",
    mixins: [requestMixins],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            input: {
                userName: "",
                password: "",
            },
            token: "",
            error_status: false,
            messages: [],
        };
    },
    validations() {
        return {
            input: {
                userName: { required },
                password: { required },
            },
        };
    },
    methods: {
        async login() {
            const isFormCorrect = await this.v$.$validate();

            if (isFormCorrect) {
                this.loading = true;
                this.storeMethod("auth/login", this.input)
                    .then((data) => {
                        this.token = data.jwt;
                        this.$store.dispatch("login", this.token);
                        this.$store.dispatch(
                            "storeAuthorityLists",
                            data.authorityList
                        );
                        this.$router.push({ name: "dashboard" });
                    })
                    .catch(() => {
                        this.error_status = true;
                        this.input = {};
                        this.messages = [
                            {
                                severity: "error",
                                content:
                                    "These credentials do not match our records.",
                                id: this.count++,
                            },
                        ];
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.p-col-6 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    left: 0;
    right: 0;
    margin: 0 auto;
}
</style>